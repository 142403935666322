import { gql, MutationHookOptions, useMutation } from '@apollo/client'

import {
  BulkUpdateInboxMessages,
  BulkUpdateInboxMessagesVariables,
  CreateInboxMessage,
  CreateInboxMessageVariables,
  RemoveAssociatedTicket,
  RemoveAssociatedTicketVariables,
} from './__generated__/types'
import { FrgUserForLink } from './user'

export const GET_INBOX_MESSAGE = gql`
  query GetInboxMessage($id: ID!) {
    companyEmailAccountMessage(id: $id) {
      id
      threadId
      fromEmail
      companyEmailAccount {
        id
        email
      }
      status
      assignee {
        id
      }
      assignedTeam {
        id
      }
    }
  }
`

const FrgInboxMessageForHistory = gql`
  ${FrgUserForLink}
  fragment FrgInboxMessageForHistory on CompanyEmailAccountMessageDef {
    id
    eventLog(
      fromDate: $fromDate
      toDate: $toDate
      first: $first
      offset: $offset
      addCompanyAccountEmailMessageEvents: $addCompanyAccountEmailMessageEvents
      companyAccountEmailMessageEventTypes: $companyAccountEmailMessageEventTypes
    ) {
      edges {
        ... on CompanyEmailAccountMessageEventDef {
          id
          createdAt
          createdBy {
            ...FrgUserForLink
          }
          companyEmailAccountMessageEventType: eventType
          companyEmailAccountMessage {
            id
            isInbound
            createdAt
            body
            subject
            fromEmail
            toEmail
            companyEmailAccount {
              id
              email
            }
            threadStarter {
              id
            }
            attachments {
              id
              file
              mimeType
            }
          }
          fromStatus
          toStatus
          fromAssignee {
            ...FrgUserForLink
          }
          toAssignee {
            ...FrgUserForLink
          }
          fromAssignedTeam {
            id
            name
          }
          toAssignedTeam {
            id
            name
          }
          fromAssociatedTickets {
            edges {
              node {
                id
              }
            }
          }
          toAssociatedTickets {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const GET_INBOX_MESSAGE_HISTORY = gql`
  ${FrgInboxMessageForHistory}
  query GetInboxMessageHistory(
    $id: ID!
    $fromDate: Date
    $toDate: Date
    $first: Int
    $offset: Int
    $addCompanyAccountEmailMessageEvents: Boolean
    $companyAccountEmailMessageEventTypes: [CompanyEmailAccountMessageEventType]
  ) {
    companyEmailAccountMessage(id: $id) {
      ...FrgInboxMessageForHistory
    }
  }
`

export const ON_INBOX_MESSAGE_CHANGED = gql`
  ${FrgInboxMessageForHistory}
  subscription OnInboxMessageChanged(
    $id: ID!
    $fromDate: Date
    $toDate: Date
    $first: Int
    $offset: Int
    $addCompanyAccountEmailMessageEvents: Boolean
    $companyAccountEmailMessageEventTypes: [CompanyEmailAccountMessageEventType]
  ) {
    onCompanyEmailAccountMessageChanged(companyEmailAccountMessageId: $id) {
      companyEmailAccountMessage {
        ...FrgInboxMessageForHistory
      }
    }
  }
`

export const GET_ALL_INBOX_MESSAGES = gql`
  ${FrgUserForLink}
  query GetAllInboxMessages(
    $order: String
    $orderBy: String
    $offset: Int = 0
    $first: Int
    $search: String
    $receiverEmail: [ID]
    $status: [String]
    $ticketType: [ID]
    $assignee: [ID]
    $assignedTeam: [ID]
    $sentAtFrom: DateTime
    $sentAtTo: DateTime
  ) {
    allCompanyEmailAccountMessages(
      order: $order
      orderBy: $orderBy
      offset: $offset
      first: $first
      search: $search
      receiverEmail: $receiverEmail
      status: $status
      ticketType: $ticketType
      assignee: $assignee
      assignedTeam: $assignedTeam
      sentAtFrom: $sentAtFrom
      sentAtTo: $sentAtTo
    ) {
      totalCount
      edges {
        node {
          id
          fromEmail
          toEmail
          companyEmailAccount {
            id
            email
            company {
              id
            }
          }
          status
          associatedTicketsCount
          assignee {
            ...FrgUserForLink
          }
          assignedTeam {
            id
            name
          }
          ticket {
            id
            status
          }
          sendAt
        }
      }
    }
  }
`

export const DELETE_INBOX_MESSAGE = gql`
  mutation DeleteInboxMessage($id: ID!) {
    deleteCompanyEmailAccountMessage(selfId: $id) {
      status
    }
  }
`

const FrgCompanyEmailAccountMessage = gql`
  fragment FrgCompanyEmailAccountMessage on CompanyEmailAccountMessageDef {
    id
    associatedTickets {
      edges {
        node {
          id
          ticketType {
            name
          }
          status
        }
      }
    }
  }
`

export const GET_ASSOCIATED_TICKETS = gql`
  ${FrgCompanyEmailAccountMessage}
  query GetAssociatedTickets($id: ID!) {
    companyEmailAccountMessage(id: $id) {
      ...FrgCompanyEmailAccountMessage
    }
  }
`

export const ADD_ASSOCIATED_TICKET = gql`
  ${FrgCompanyEmailAccountMessage}
  mutation AddAssociatedTicket($id: ID!, $ticketIds: [ID!]!) {
    addAssociatedTicketsToCompanyEmailAccountMessage(
      selfId: $id
      ticketIds: $ticketIds
    ) {
      companyEmailAccountMessage {
        ...FrgCompanyEmailAccountMessage
      }
    }
  }
`

export const REMOVE_ASSOCIATED_TICKET = gql`
  ${FrgCompanyEmailAccountMessage}
  mutation RemoveAssociatedTicket($id: ID!, $ticketIds: [ID!]!) {
    removeAssociatedTicketsFromCompanyEmailAccountMessage(
      selfId: $id
      ticketIds: $ticketIds
    ) {
      companyEmailAccountMessage {
        ...FrgCompanyEmailAccountMessage
      }
    }
  }
`

export const useRemoveAssociatedTicket = (
  options?: MutationHookOptions<
    RemoveAssociatedTicket,
    RemoveAssociatedTicketVariables
  >
) =>
  useMutation<RemoveAssociatedTicket, RemoveAssociatedTicketVariables>(
    REMOVE_ASSOCIATED_TICKET,
    options
  )

export const BULK_UPDATE_INBOX_MESSAGES = gql`
  mutation BulkUpdateInboxMessages(
    $ids: [ID!]!
    $input: BulkUpdateCompanyEmailAccountMessageInput!
  ) {
    bulkUpdateCompanyEmailAccountMessage(selfIds: $ids, input: $input) {
      companyEmailAccountMessages {
        id
      }
    }
  }
`

export const useBulkUpdateInboxMessages = (
  options?: MutationHookOptions<
    BulkUpdateInboxMessages,
    BulkUpdateInboxMessagesVariables
  >
) =>
  useMutation<BulkUpdateInboxMessages, BulkUpdateInboxMessagesVariables>(
    BULK_UPDATE_INBOX_MESSAGES,
    options
  )

export const CREATE_INBOX_MESSAGE = gql`
  mutation CreateInboxMessage($input: CreateCompanyEmailAccountMessageInput!) {
    createCompanyEmailAccountMessage(input: $input) {
      companyEmailAccountMessage {
        id
      }
    }
  }
`

export const useCreateInboxMessage = (
  options?: MutationHookOptions<CreateInboxMessage, CreateInboxMessageVariables>
) =>
  useMutation<CreateInboxMessage, CreateInboxMessageVariables>(
    CREATE_INBOX_MESSAGE,
    options
  )
