import React, { CSSProperties, Fragment, ReactNode } from 'react'
import { T } from '@transifex/react'
import { TextProps } from 'antd/es/typography/Text'
import dayjs, { Dayjs } from 'dayjs'
import {
  TableColumnProps,
  Row,
  Col,
  TagProps,
  Tag,
  Space,
  Button,
  ButtonProps,
  SpaceProps,
  Typography as AntdTypography,
  Radio,
  RadioProps,
  Avatar,
  AvatarProps,
  Tooltip,
} from 'src/antd'

import {
  TX_END_DATE,
  TX_NO_DATA,
  TX_NO_END_DATE,
  TX_NO_START_DATE,
  TX_START_DATE,
} from '../../transifex'
import { ActionButtons, TActionButtonsType } from '../ActionButtons'
import {
  DropdownSimple,
  TDropdownSimpleProps,
  TDropdownSimpleComponentsProps,
} from '../DropdownSimple'
import { FromTo } from '../FromTo'
import { TagsMultiple, TagsMultipleProps } from '../TagsMultiple'
// eslint-disable-next-line import/no-cycle
import { DateComponent, TDateFormatType } from '../TimeComponent/DateComponent'
import { Typography, TTypographyProps } from '../Typography'

const { Text } = AntdTypography

export const NO_DATA = <Typography type="secondary">{TX_NO_DATA}</Typography>

type TColumnRenderFnArgs<Data, Val extends $TSFixMe = $TSFixMe> = [
  value: Val,
  record: Data,
  index: number
]
type TColumnRenderFn<Data, R, Val extends $TSFixMe = $TSFixMe> = (
  ...args1: TColumnRenderFnArgs<Data, Val>
) => R

// TODO: fix $TSFixMe
type RecordAny = Record<string, $TSFixMe>

export const getNullableColumn =
  <Data extends RecordAny>(
    children: TableColumnProps<Data>['render'] = (val) => val,
    fallback: ReactNode = NO_DATA,
    truthTestFn: (val: $TSFixMe) => boolean = (val) =>
      val === 0 ? true : !!val
  ): TableColumnProps<Data>['render'] =>
  (...args) => {
    const [val] = args
    return truthTestFn(val) ? children(...args) : fallback
  }

export const getActionsDropDown =
  <Data extends RecordAny>(
    args: Omit<TActionButtonsType, 'data'>
  ): TableColumnProps<Data>['render'] =>
  (_, data) => {
    const { actions } = args

    return (
      <ActionButtons
        actions={actions}
        data={data}
        dropdownOnly={args?.dropdownOnly}
        size={args?.size}
        style={args?.style}
      />
    )
  }

export const getActionsDropDown2 =
  <Data extends RecordAny>(
    args: {
      actions: Array<{
        action: TActionButtonsType['actions'][0]
        cond?: (d: Data) => boolean
      }>
    } & Omit<TActionButtonsType, 'data' | 'actions'>
  ): TableColumnProps<Data>['render'] =>
  (_, data) => {
    const { actions, ...rest } = args

    return (
      <ActionButtons
        {...rest}
        actions={actions
          .filter((a) => (!a.cond ? true : a.cond?.(data)))
          .map((a) => a.action)}
        data={data}
      />
    )
  }

export const getSimpleColumn = <
  Data extends RecordAny
>(): TableColumnProps<Data>['render'] =>
  getNullableColumn((value) => (
    <Typography
      style={{
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
      }}
    >
      {value}
    </Typography>
  ))

export const getDateColumn = <Data extends RecordAny>(
  format?: TDateFormatType,
  inputFormat?: string,
  nullValue = undefined
): TableColumnProps<Data>['render'] =>
  getNullableColumn(
    (value) => (
      <Typography
        style={{
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
        }}
      >
        <DateComponent time={value} format={format} inputFormat={inputFormat} />
      </Typography>
    ),
    nullValue
  )

export type DatePeriodCell = {
  startDate?: Dayjs
  endDate?: Dayjs
  format?: TDateFormatType
}

export const getDatePeriodCell =
  <RecordType extends RecordAny, Data extends DatePeriodCell = DatePeriodCell>(
    format: TDateFormatType = 'll'
  ): TableColumnProps<RecordType>['render'] =>
  ({ startDate, endDate }: Data) => {
    return (
      <>
        <FromTo
          short
          from={
            <span style={{ whiteSpace: 'pre' }}>
              <DateComponent
                format={format}
                time={startDate}
                tooltip={startDate ? TX_START_DATE : TX_NO_START_DATE}
              />
            </span>
          }
          to={
            <span style={{ whiteSpace: 'pre' }}>
              <DateComponent
                format={format}
                time={endDate}
                tooltip={endDate ? TX_END_DATE : TX_NO_END_DATE}
              />
            </span>
          }
          wrap={false}
        />
      </>
    )
  }

/** @deprecated */
export const getTypographyTextColumn =
  <Data extends RecordAny>(
    children: TableColumnProps<Data>['render'] = (val) => val,
    textProps?: TextProps
  ): TableColumnProps<Data>['render'] =>
  (...args) =>
    <Text {...(textProps || {})}>{children(...args)}</Text>

export const getTypographyColumn =
  <Data extends RecordAny>(
    children: TableColumnProps<Data>['render'] = (val) => val,
    props?: TTypographyProps
  ): TableColumnProps<Data>['render'] =>
  (...args) =>
    <Typography {...(props || {})}>{children(...args)}</Typography>

export const getTagColumn =
  <Data extends RecordAny>(
    colorMap?: Record<string, TagProps['color']>,
    render?: (val: string) => ReactNode,
    style?: CSSProperties
  ): TableColumnProps<Data>['render'] =>
  (val: string) =>
    (
      <Tag
        style={{ maxWidth: '100%', ...style }}
        color={colorMap?.[val]}
        variant="subtle"
      >
        <Typography
          style={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            lineHeight: 'inherit',
            color: 'inherit',
            textDecoration: 'inherit',
          }}
        >
          {render?.(val) || val}
        </Typography>
      </Tag>
    )

export const getTagsColumn = <Data extends RecordAny>(
  colorMap?: Record<string, TagProps['color']>,
  render?: (val: string) => ReactNode
): TableColumnProps<Data>['render'] => {
  const renderTag = getTagColumn(colorMap, render)
  return (vals: string[], ...rest) => (
    <Row gutter={[0, 8]}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {vals.map((val) => (
        <Col>{renderTag?.(val, ...rest)}</Col>
      ))}
    </Row>
  )
}

export const getMultiTagsCell =
  <Data extends RecordAny>(
    children?: TableColumnProps<Data>['render']
  ): TableColumnProps<Data>['render'] =>
  (tags: TagsMultipleProps['tags'], ...rest2) => {
    return (
      <>
        <TagsMultiple maxTagsToShow="responsive" tags={tags} />
        {children?.(tags, ...rest2)}
      </>
    )
  }

export const getActionsBtns =
  <Data extends RecordAny>(args: {
    actions: {
      btnProps?: ButtonProps & { tooltip?: ReactNode }
      handler: (d: Data) => void
      cond?: (d: Data) => boolean
    }[]
    btnProps?: ButtonProps
    spaceProps?: SpaceProps
  }): TableColumnProps<Data>['render'] =>
  (_, data) => {
    const { actions, btnProps = {}, spaceProps = {} } = args
    return (
      <Space size="middle" {...spaceProps}>
        {React.Children.toArray(
          actions
            .filter(({ cond = () => true }) => cond(data))
            .map(({ handler, btnProps: { tooltip, ...btnProps2 } = {} }) => {
              const button = (
                <Button
                  {...btnProps}
                  {...btnProps2}
                  onClick={(...args1) => {
                    handler(data)
                    btnProps?.onClick?.(...args1)
                    btnProps2?.onClick?.(...args1)
                  }}
                />
              )

              if (tooltip) {
                return <Tooltip title={tooltip}>{button}</Tooltip>
              }

              return button
            })
        )}
      </Space>
    )
  }

export const getSpaceColumn =
  <Data extends RecordAny>(
    children: TableColumnProps<Data>['render'] = (val) => val,
    spaceProps: SpaceProps
  ): TableColumnProps<Data>['render'] =>
  (...args) => {
    return <Space {...spaceProps}>{children(...args)}</Space>
  }

export type GetAvatarColumnProps = {
  avatarProps: AvatarProps
}

export const getAvatarColumn =
  <
    RecordType extends RecordAny,
    Data extends GetAvatarColumnProps = GetAvatarColumnProps
  >(
    children?: TColumnRenderFn<RecordType, $TSFixMe, Omit<Data, 'avatarProps'>>,
    baseAvatarProps?: AvatarProps
  ): TableColumnProps<RecordType>['render'] =>
  ({ avatarProps, ...rest }: Data, ...rest2) => {
    return (
      <>
        <Avatar {...baseAvatarProps} {...avatarProps} />
        {children?.(rest, ...rest2)}
      </>
    )
  }

type Opt = {
  label: ReactNode
  value: string
  checked?: boolean
}

export type TGetDropdownSimpleWithRadioBtnsArgs<Data> = {
  getOpts: TColumnRenderFn<Data, Opt[]>
  dropdownSimpleProps?: Omit<TDropdownSimpleProps, 'items'>
  children?: TColumnRenderFn<Data, ReactNode>
  radioProps?: Omit<RadioProps, 'value' | 'checked' | 'key'>
  defaultToggler?: TColumnRenderFn<
    Data,
    TDropdownSimpleComponentsProps['TDropdownSimpleTogglerProps']
  >
  onSelect?: (args: {
    columnArgs: TColumnRenderFnArgs<Data>
    value: $TSFixMe
  }) => void
}

export const getDropdownSimpleWithRadioBtns = <Data extends RecordAny>(
  args: TGetDropdownSimpleWithRadioBtnsArgs<Data>
): TableColumnProps<Data>['render'] => {
  const {
    getOpts,
    dropdownSimpleProps,
    children,
    defaultToggler,
    radioProps,
    onSelect,
  } = args
  return (...innerArgs) => {
    return (
      <DropdownSimple
        {...dropdownSimpleProps}
        items={getOpts(...innerArgs).map(({ label, value, checked }) => ({
          key: value,
          label: (
            <Radio
              {...radioProps}
              value={value}
              checked={checked}
              key={value}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {label}
            </Radio>
          ),
          onClick: () => {
            onSelect?.({ columnArgs: innerArgs, value })
          },
        }))}
        dropdownProps={{
          placement: 'bottomLeft',
          ...dropdownSimpleProps?.dropdownProps,
        }}
      >
        {children?.(...innerArgs) ||
          (defaultToggler && (
            <DropdownSimple.DropdownSimpleToggler
              {...defaultToggler(...innerArgs)}
            />
          ))}
      </DropdownSimple>
    )
  }
}

export const getArrayCell =
  <RecordType extends RecordAny, Data extends Array<$TSFixMe>>(
    children: TColumnRenderFn<RecordType, $TSFixMe, Data[0]> = (val) => val
  ): TableColumnProps<RecordType>['render'] =>
  (cells: Data, ...rest2) => {
    return cells.map((c, index) => (
      <Fragment key={index}>{children(c, ...rest2)}</Fragment>
    ))
  }

type TDistanceVariant = 'mm' | 'cm' | 'm' | 'km'

export function convertMeters(meters = 0, variant: TDistanceVariant = 'km') {
  let convertedValue = meters
  switch (variant) {
    case 'mm':
      convertedValue = meters * 1000
      break
    case 'cm':
      convertedValue = meters * 100
      break
    case 'km':
      convertedValue = meters / 1000
      break
    default:
      break
  }
  return [
    Number.isInteger(convertedValue)
      ? convertedValue
      : convertedValue.toFixed(2),
    variant,
  ].join(' ')
}

export const getDistanceColumn =
  <Data extends RecordAny>(
    variant?: TDistanceVariant
  ): TableColumnProps<Data>['render'] =>
  (value) =>
    <Typography>{convertMeters(value, variant)}</Typography>

export const getDiffDistanceColumn = <Data extends RecordAny>(
  dependency: keyof Data,
  threshold = 1000,
  variant?: TDistanceVariant
): TableColumnProps<Data>['render'] =>
  getNullableColumn(
    (value, record) => {
      const diff = value - record[dependency]
      const isSame = Math.abs(diff) < threshold
      const sign = Math.sign(diff)
      return (
        <>
          <Typography>{convertMeters(value, variant)}</Typography>
          <br />
          {isSame && <Tag color="green">+0 km</Tag>}
          {!isSame && (
            <Tag color={sign === 1 ? 'red' : 'orange'}>
              {sign === 1 && '+'}
              {convertMeters(diff, variant)}
            </Tag>
          )}
        </>
      )
    },
    undefined,
    (v) => !!v
  )

export function convertDurationToTime(seconds = 0) {
  const duration = dayjs.duration(seconds, 'seconds')
  const years = duration.years()
  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()

  if (years) {
    return (
      <T _str="{years, plural, one {1 year} other {# years}}" years={years} />
    )
  }

  if (months) {
    return (
      <T
        _str="{months, plural, one {1 month} other {# months}}"
        months={months}
      />
    )
  }

  if (days) {
    return <T _str="{days, plural, one {1 day} other {# days}}" days={days} />
  }

  if (hours > 0 && minutes > 0) {
    return <T _str="{hours}hr {minutes}min" hours={hours} minutes={minutes} />
  }

  if (hours > 0) {
    return <T _str="{hours}hr" hours={hours} />
  }

  return <T _str="{minutes}min" minutes={minutes} />
}

export const getDurationColumn =
  <Data extends RecordAny>(): TableColumnProps<Data>['render'] =>
  (value) =>
    <Typography>{convertDurationToTime(value)}</Typography>

export const getDiffDurationColumn = <Data extends RecordAny>(
  dependency: keyof Data,
  threshold = 60
): TableColumnProps<Data>['render'] =>
  getNullableColumn(
    (value, record) => {
      const diff = value - record[dependency]
      const absDiff = Math.abs(diff)
      const isSame = absDiff < threshold
      const sign = Math.sign(diff)
      return (
        <>
          <Typography>{convertDurationToTime(value)}</Typography>
          <br />
          {isSame && (
            <Tag color="green">
              <T _str="On time" />
            </Tag>
          )}
          {!isSame && (
            <Tag color={sign === 1 ? 'red' : 'orange'}>
              {sign === 1 ? '+' : '-'}
              {convertDurationToTime(absDiff)}
            </Tag>
          )}
        </>
      )
    },
    undefined,
    (v) => !!v
  )

export const getDiffTimeColumn = <Data extends RecordAny>(
  dependency: keyof Data,
  threshold = 60
): TableColumnProps<Data>['render'] =>
  getNullableColumn((value, record) => {
    const diff = dayjs(value).diff(dayjs(record[dependency]), 'seconds')
    const absDiff = Math.abs(diff)
    const isSame = absDiff < threshold
    const sign = Math.sign(diff)
    const diffTime = convertDurationToTime(absDiff)
    return (
      <>
        <Typography>
          <DateComponent time={value} format="calendar" />
        </Typography>
        <br />
        {isSame && (
          <Tag color="green">
            <T _str="On time" />
          </Tag>
        )}
        {!isSame && (
          <Tag color={sign === 1 ? 'red' : 'orange'}>
            {sign === 1 && <T _str="{time} late" time={diffTime} />}
            {sign === -1 && <T _str="{time} earlier" time={diffTime} />}
          </Tag>
        )}
      </>
    )
  })
