import React, { ReactNode } from 'react'
import { TreeDataNode, PopoverProps, Popover } from 'src/antd'
import styled from 'styled-components'

import { blue300, gray300, textEmpty } from 'src/styles/color-theme'

const PopoverWithOverlayClassname = ({
  className,
  ...props
}: PopoverProps & { className?: string }) => (
  <Popover {...props} overlayClassName={className} />
)

export const StyledPopover = styled(PopoverWithOverlayClassname)`
  animation-duration: 0s;
  .ant-popover-inner-content {
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-popover-title {
    padding: 14px 16px;
  }
  .ant-popover-arrow {
    display: none;
  }
  &.ant-popover-placement-bottom,
  &.ant-popover-placement-bottomLeft,
  &.ant-popover-placement-bottomRight {
    padding-top: 0;
  }
`

export const StyledPlaceholderWrapper = styled.div<{
  $isDraggingOver: boolean
}>`
  padding: 8px 12px;
  background-color: ${gray300};
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ $isDraggingOver }) => ($isDraggingOver ? blue300 : textEmpty)};
  border: ${({ $isDraggingOver }) =>
    `2px solid ${$isDraggingOver ? blue300 : 'transparent'}`};
`

export type UseTreeFilterAndOrderBaseArgs<T extends string> = {
  init: {
    checked: T[]
    order: T[]
  }
  defaultFixedColumns?: T[]
  columns: Record<T, TreeDataNode>
  popover?: Omit<PopoverProps, 'title' | 'content'>
  hideColumns?: (T | '__actions')[]
  hideTreeColumns?: T[]
}
export type UseTreeFilterAndOrderReturn<T> = {
  state: T[]
  fixedColumns?: T[]
  jsx: {
    toggler: ReactNode
  }
}
export const reorder = (
  list: string[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}
