import React, { useMemo } from 'react'
import { useT } from '@transifex/react'
import dayjs, { Dayjs } from 'dayjs'

import { NO_DATA } from '../table-kit'
import { WHTooltip } from '../Tooltip'

export type TDateFormatType = 'lll' | 'll' | 'LT' | 'LTS' | 'calendar'

type TDateComponentProps = {
  format?: TDateFormatType
  time: string | Dayjs | undefined
  inputFormat?: string
  tooltip?: React.ReactNode
}

export const dateTooltipFormatMap: Record<TDateFormatType, string | undefined> =
  {
    lll: undefined,
    ll: 'lll',
    LT: 'LTS',
    LTS: undefined,
    calendar: undefined,
  }

export const DateComponent = ({
  format = 'lll',
  time,
  inputFormat,
  tooltip,
}: TDateComponentProps) => {
  const t = useT()
  const formats = useMemo(
    () => ({
      lastDay: `[${t('Yesterday at')}] LT`,
      sameDay: `[${t('Today at')}] LT`,
      nextDay: `[${t('Tomorrow at')}] LT`,
      lastWeek: `LL [${t('at')}] LT`,
      nextWeek: `LL [${t('at')}] LT`,
      sameElse: `LL [${t('at')}] LT`,
    }),
    [t]
  )

  if (!time) {
    return <WHTooltip title={tooltip}>{NO_DATA}</WHTooltip>
  }

  const value =
    format === 'calendar'
      ? dayjs(time).calendar(null, formats)
      : dayjs(time, inputFormat).format(format)
  const tooltipText =
    tooltip ||
    (format === 'calendar'
      ? value
      : dayjs(time, inputFormat).format(dateTooltipFormatMap[format]))

  return <WHTooltip title={tooltipText}>{value}</WHTooltip>
}
