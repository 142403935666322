import { ReactNode } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import {
  TX_CUSTOM,
  TX_NONE,
  TX_THIS_MONTH,
  TX_TODAY,
  TxLastDaysNum,
} from '../../transifex'

export type TQuickDateOption =
  | 'today'
  | 'last_7_days'
  | 'this_month'
  | 'last_30_days'
  | 'last_90_days'
  | 'last_365_days'
  | 'custom'
  | 'none'

export const quickDateOptionsLabelMap: Record<TQuickDateOption, ReactNode> = {
  today: TX_TODAY,
  custom: TX_CUSTOM,
  last_7_days: TxLastDaysNum(7),
  this_month: TX_THIS_MONTH,
  last_30_days: TxLastDaysNum(30),
  last_90_days: TxLastDaysNum(90),
  last_365_days: TxLastDaysNum(365),
  none: TX_NONE,
}

export const quickDateOptions = Object.keys(quickDateOptionsLabelMap).map(
  (key) => ({
    label: quickDateOptionsLabelMap[key as TQuickDateOption],
    value: key as TQuickDateOption,
  })
)

export const quickDateOptionMap: Record<
  TQuickDateOption,
  () => [Dayjs, Dayjs] | null
> = {
  today: () => [dayjs().startOf('day'), dayjs().endOf('day')],
  last_7_days: () => [
    dayjs().subtract(6, 'days').startOf('day'),
    dayjs().endOf('day'),
  ],
  this_month: () => [dayjs().startOf('month'), dayjs().endOf('month')],
  last_30_days: () => [
    dayjs().subtract(29, 'days').startOf('day'),
    dayjs().endOf('day'),
  ],
  last_90_days: () => [
    dayjs().subtract(89, 'days').startOf('day'),
    dayjs().endOf('day'),
  ],
  last_365_days: () => [
    dayjs().subtract(364, 'days').startOf('day'),
    dayjs().endOf('day'),
  ],
  custom: () => [dayjs().subtract(1, 'day'), dayjs().add(1, 'day')],
  none: () => null,
}

export const getQuickDateSelectValue = (
  value?: [Dayjs, Dayjs]
): TQuickDateOption => {
  if (!value) {
    return 'none'
    // eslint-disable-next-line no-else-return
  } else {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in quickDateOptionsLabelMap) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const date = quickDateOptionMap[key as TQuickDateOption]()
      if (
        date?.[0].isSame(value[0], 'date') &&
        date?.[1].isSame(value[1], 'date')
      ) {
        return key as TQuickDateOption
      }
    }
  }

  return 'custom'
}
