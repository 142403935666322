import { gql } from '@apollo/client'

import { FrgUserForLink } from './user'

export const GET_CONTAINER_GROUP_HISTORY = gql`
  ${FrgUserForLink}
  query GetContainerGroupEventLogs(
    $id: ID!
    $fromDate: Date
    $toDate: Date
    $first: Int
    $offset: Int
    $containerGroupEventTypes: [ContainerGroupEventTypesGraphene]
    $addContainerEvents: Boolean
    $containerEventTypes: [DeviceContainerEventTypesGraphene]
  ) {
    containerGroup(id: $id) {
      id
      name
      eventLog(
        fromDate: $fromDate
        toDate: $toDate
        first: $first
        offset: $offset
        containerGroupEventTypes: $containerGroupEventTypes
        addContainerEvents: $addContainerEvents
        containerEventTypes: $containerEventTypes
      ) {
        totalCount
        edges {
          ... on ContainerGroupEventDef {
            id
            user {
              ...FrgUserForLink
            }
            createdAt
            state
            fromName
            toName
            fromLocation {
              id
              name
              longitude
              latitude
              municipalityCode
              postalCode
              postalCodeName
              street
              streetNumber
            }
            toLocation {
              id
              name
              longitude
              latitude
              municipalityCode
              postalCode
              postalCodeName
              street
              streetNumber
            }
            fromLocationCurb
            toLocationCurb
            containerGroupEventType: eventType
          }
          ... on ContainerEventDef {
            id
            createdAt
          }
        }
      }
    }
  }
`
