import { gql } from '@apollo/client'

import { FrgUserForLink } from 'src/api'

export const GET = gql`
  ${FrgUserForLink}
  query GetDriverInfoPopover($id: ID!) {
    driver(id: $id) {
      id
      driverType
      user {
        ...FrgUserForLink
        phoneNumber
        email
        username
      }
    }
  }
`
