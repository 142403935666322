import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { MetaTypeDisplayOnType } from 'src/gen-types'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TMetaValuesInputTypes =
  | `SELECT`
  | `MULTI_SELECT`
  | `TEXT_AREA`
  | `NUMBER`

export type TMetaValuesEntityTypes = `PROPERTY` | `PROPERTY_GROUP` | `CONTAINER`

export const metaValuesInputTypesLabelMap: Record<
  TMetaValuesInputTypes,
  ReactNode
> = {
  SELECT: <T _str="Selection" />,
  MULTI_SELECT: <T _str="Multi Selection" />,
  NUMBER: <T _str="Number" />,
  TEXT_AREA: <T _str="Text Area" />,
}

export const metaValuesInputTypesSectionTitleMap: Record<
  TMetaValuesInputTypes,
  ReactNode
> = {
  SELECT: <T _str="Select option" />,
  MULTI_SELECT: <T _str="Multi-select option" />,
  NUMBER: <T _str="Number input" />,
  TEXT_AREA: <T _str="Text area" />,
}

export const metaValuesEntityTypesLabelMap: Record<
  TMetaValuesEntityTypes,
  ReactNode
> = {
  PROPERTY: <T _str="Property" />,
  PROPERTY_GROUP: <T _str="Property Group" />,
  CONTAINER: <T _str="Container" />,
}

export const metaValuesEntityTypesDisplayOnMap: Record<
  TMetaValuesEntityTypes,
  MetaTypeDisplayOnType[]
> = {
  PROPERTY: [
    MetaTypeDisplayOnType.PROPERTY_CARD,
    MetaTypeDisplayOnType.PROPERTY_TABLE,
    MetaTypeDisplayOnType.NAVIGATION_APP,
  ],
  PROPERTY_GROUP: [MetaTypeDisplayOnType.NAVIGATION_APP],
  CONTAINER: [
    MetaTypeDisplayOnType.AGREEMENT_TABLE,
    MetaTypeDisplayOnType.CONTAINER_TABLE,
    MetaTypeDisplayOnType.NAVIGATION_APP,
  ],
}

export const metaValuesOptionsEnabled: Record<TMetaValuesInputTypes, boolean> =
  {
    SELECT: true,
    MULTI_SELECT: true,
    NUMBER: false,
    TEXT_AREA: false,
  }

export const metaValuesInputTypeOptions =
  createOptionsFromLabelMap<TMetaValuesInputTypes>(metaValuesInputTypesLabelMap)
export const metaValuesEntityTypeOptions =
  createOptionsFromLabelMap<TMetaValuesEntityTypes>(
    metaValuesEntityTypesLabelMap
  )

export const getMetaValuesInputTypesPlaceholder = (
  type: TMetaValuesInputTypes,
  t: (str: string) => string
) => {
  switch (type) {
    case 'SELECT':
      return t('Select 1 option')
    case 'MULTI_SELECT':
      return t('Select 1 ore more options')
    case 'NUMBER':
      return t('Enter a value')
    case 'TEXT_AREA':
      return t('Enter text')
    default:
      return ''
  }
}
