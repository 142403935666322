import { gql } from '@apollo/client'

import { FrgUserForLink } from './user'

export const GET_ALL_IMPORTS = gql`
  ${FrgUserForLink}
  query GetAllImports(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: String
    $order: String
    $offset: Int
    $importType: [ImportTypes]
    $startDate: Date
    $endDate: Date
    $users: [ID]
  ) {
    allImportHistory(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      order: $order
      offset: $offset
      importType: $importType
      startDate: $startDate
      endDate: $endDate
      users: $users
    ) {
      totalCount
      edges {
        node {
          id
          user {
            ...FrgUserForLink
          }
          completedAt
          importType
          createdAt
          progress
          status
        }
      }
    }
  }
`

export const GET_IMPORT_DATA = gql`
  query GetImportData($id: ID!) {
    importHistory(id: $id) {
      id
    }
  }
`

export const DELETE_IMPORT = gql`
  mutation DeleteImport($importId: ID!) {
    deleteImportHistory(selfId: $importId) {
      status
    }
  }
`
