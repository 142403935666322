import React, { FC, CSSProperties } from 'react'
import { Avatar, AvatarProps, Badge, Skeleton } from 'src/antd'

import { Typography, TTypographyProps } from '../Typography'

export type TAvatarWithNameProps = {
  avatarProps?: AvatarProps & { hide?: boolean; online?: boolean }
  typographyProps?: TTypographyProps
  loading?: boolean
  wrapperProps?: CSSProperties
}

export const AvatarWithName: FC<TAvatarWithNameProps> = ({
  children,
  avatarProps,
  typographyProps,
  loading,
  wrapperProps,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        columnGap: 8,
        alignItems: 'center',
        ...wrapperProps,
      }}
    >
      <div className="user-avatar-div">
        {loading ? (
          <Skeleton.Avatar size="small" />
        ) : (
          <Badge
            dot={typeof avatarProps?.online === 'boolean'}
            color={avatarProps?.online ? 'green' : 'orange'}
            offset={[-4, '95%']}
          >
            <Avatar {...avatarProps} />
          </Badge>
        )}
      </div>
      {Boolean(avatarProps?.children || children) && (
        <div style={{ textAlign: 'start', flexGrow: 1 }}>
          <Typography
            loadingSize="full"
            loading={loading}
            ellipsis
            {...typographyProps}
          >
            {avatarProps?.children || children}
          </Typography>
        </div>
      )}
    </div>
  )
}
