import React from 'react'
import { T } from '@transifex/react'

import { CoreUserAbsenceAbsenceTypeChoices } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const absenceTypeLabelMap: Record<
  CoreUserAbsenceAbsenceTypeChoices,
  React.ReactNode
> = {
  [CoreUserAbsenceAbsenceTypeChoices.HOLIDAY]: <T _str="Holiday" />,
  [CoreUserAbsenceAbsenceTypeChoices.SICK]: <T _str="Sick" />,
  [CoreUserAbsenceAbsenceTypeChoices.MATERNITY]: <T _str="Maternity" />,
  [CoreUserAbsenceAbsenceTypeChoices.OTHER]: <T _str="Other" />,
}

export const ABSENCE_TYPE_OPTIONS =
  createOptionsFromLabelMap(absenceTypeLabelMap)
