import { gql } from '@apollo/client'

import { FrgUserForLink } from './user'

export const FrgUserAbsence = gql`
  ${FrgUserForLink}
  fragment FrgUserAbsence on UserAbsenceType {
    id
    project {
      id
      name
    }
    user {
      ...FrgUserForLink
    }
    replacementUser {
      ...FrgUserForLink
    }
    start
    end
    absenceType
  }
`

export const GET_ALL_USER_ABSENCE = gql`
  ${FrgUserAbsence}
  query GetAllUserAbsence(
    $activeProjects: String!
    $users: String
    $offset: Int
    $first: Int
    $start_Gte: Date
    $end_Lte: Date
  ) {
    allUserAbsences(
      activeProjects: $activeProjects
      users: $users
      offset: $offset
      first: $first
      start_Gte: $start_Gte
      end_Lte: $end_Lte
    ) {
      totalCount
      edges {
        node {
          ...FrgUserAbsence
        }
      }
    }
  }
`

export const GET_USER_ABSENCE = gql`
  ${FrgUserAbsence}
  query GetUserAbsence($id: ID!) {
    userAbsence(id: $id) {
      ...FrgUserAbsence
    }
  }
`

export const CREATE_OR_UPDATE_USER_ABSENCE = gql`
  ${FrgUserAbsence}
  mutation CreateOrUpdateUserAbsence(
    $selfId: ID
    $projectId: ID!
    $userId: String!
    $replacementUserId: String!
    $start: Date!
    $end: Date!
    $absenceType: String!
  ) {
    updateOrCreateUserAbsence(
      selfId: $selfId
      projectId: $projectId
      userId: $userId
      replacementUserId: $replacementUserId
      start: $start
      end: $end
      absenceType: $absenceType
    ) {
      userAbsence {
        ...FrgUserAbsence
      }
    }
  }
`
