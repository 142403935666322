import { ReactNode } from 'react'
import { create } from 'zustand'

type Store = {
  jsxMap: Record<string, ReactNode>
}

const store = create<Store>(() => ({
  jsxMap: {},
}))

export default store
