/* eslint-disable no-underscore-dangle */
import React from 'react'
import { DatePickerProps } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { css } from 'styled-components'

import { Typography } from './Typography'

/**
 * these styles are automatically added to storybook global styles so no need to add them manually on FE.
 * src/styles.tsx
 */
export const GetDatePickerBasePropsGlobalStyles = css`
  .whf-ant-date-picker-base {
    & .ant-picker-date-panel {
      width: 304px;
    }

    & .ant-picker-content {
      margin-left: 28px;
    }

    & .ant-picker-week-number {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      margin-left: -28px;
      width: 24px;
      height: 24px;
      cursor: default;
      pointer-events: none;
    }
  }
`

export const getDatePickerBaseProps = ({
  popupClassName,
}: Pick<DatePickerProps, 'popupClassName'>): Pick<
  DatePickerProps,
  'dateRender' | 'popupClassName'
> => {
  const firstDayOfWeek = dayjs().localeData().firstDayOfWeek()
  return {
    dateRender: (current) => {
      return (
        <>
          {current.day() === firstDayOfWeek && (
            <div className="ant-picker-week-number">
              <Typography style={{ color: '#BFBFBF' }}>
                {dayjs(current).endOf('week').isoWeek()}
              </Typography>
            </div>
          )}
          <div className="ant-picker-cell-inner">{current.date()}</div>
        </>
      )
    },
    popupClassName: classNames(popupClassName, 'whf-ant-date-picker-base'),
  }
}
