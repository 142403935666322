import { gql } from '@apollo/client'

import { FrgVehicleForLink } from './vehicle'
import { FrgWasteFraction } from './waste-fraction'

export const FrgPickupSettings = gql`
  fragment FrgPickupSettings on PickupSettingsType {
    id
    allowedHoursFrom
    name
    allowedHoursTo
    amountDaysBetweenPickups
    emptyingIntervalFrom
    emptyingIntervalTo
    excludeDays
    fixedPickupsPeriod
    minimumDaysBetweenPickup
    pickupDay {
      edges {
        node {
          id
          weekday
          timeFrom
          timeTo
        }
      }
    }
    project {
      id
      name
    }
    pickupInterval
    pickupRepeatPeriod
    collectionPerWeek
    firstCollection
    containersCount
    latestCollectionCalendarDate
  }
`

export const FrgBaseContainer = gql`
  ${FrgPickupSettings}
  fragment FrgBaseContainer on ContainerType {
    id
    containerId
    description
    showOnRoute
    photo
    photoUrl
    status
    pickupMethod
    pickupSettings {
      edges {
        node {
          ...FrgPickupSettings
        }
      }
    }
    prioritizeInStartOfRoute
    wasteFraction {
      id
      name
      wasteCategory
      wasteSubstance
      weightFactor
    }
    overflowingSettings {
      id
      duration
      percentage
    }
    project {
      id
      name
      company {
        id
        name
      }
    }
  }
`

export const GET_CONTAINER_SENSOR_DATA = gql`
  ${FrgBaseContainer}
  query GetContainerWithSensorData(
    $id: ID!
    $isFillleveldeviceSet: Boolean = true
    $isLogs: Boolean = true
    $isMeasurement: Boolean = true
    $isCompany: Boolean = true
    $isFillleveldeviceSetNotificationSet: Boolean = true
    $isContainerLog: Boolean = true
    $isDeviceToContainerSetDeviceDeviceType: Boolean = true
    $isFilllevelmeasurementSet: Boolean = true
    $isContainerType: Boolean = true
    $isAvailableMeasurementFilters: Boolean = true
    $isCurrentEffective: Boolean = true
    $fillingFrequency: Boolean = true
    $fillLevelMeasurementSetCreatedAt_Lte: DateTime
    $fillLevelMeasurementSetCreatedAt_Gte: DateTime
    $currentEffectiveFrom: DateTime
    $currentEffectiveTo: DateTime
  ) {
    container(id: $id) {
      ...FrgBaseContainer
      fillingFrequency @include(if: $fillingFrequency)
      currentEffective(From: $currentEffectiveFrom, To: $currentEffectiveTo)
        @include(if: $isCurrentEffective)
      availableMeasurementFilters @include(if: $isAvailableMeasurementFilters)
      scheduledPickup
      totalCollections
      totalOverflowings
      efficient
      status
      project @include(if: $isCompany) {
        id
        name
      }
      location {
        id
        latitude
        longitude
        placeId
        name
      }
      allowedHoursStart
      allowedHoursEnd
      containerType @include(if: $isContainerType) {
        id
        name
        volume
        height
        length
        width
        diameter
        emplacement
        emptyingTime
        isCylindrical
        vehicleCoupling
      }
      measurement @include(if: $isMeasurement) {
        id
        fillPercentage
        temperature
        batteryLevel
        rsi
        createdAt
        rawMeasurements
        rawMeasurementsPercentage
      }
      logs(last: 100) @include(if: $isLogs) {
        edges {
          node {
            id
            oldFillPercentage
            createdAt
          }
        }
      }
      deviceToContainerSet @include(if: $isFillleveldeviceSet) {
        edges {
          node {
            id
            objectId
            internalHeight
            offset
            deviceAngle
            topLid
            sideLid
            endDate
            measurementSettings {
              id
              custom
              name
            }
            filllevelmeasurementSet(
              createdAt_Lte: $fillLevelMeasurementSetCreatedAt_Lte
              createdAt_Gte: $fillLevelMeasurementSetCreatedAt_Gte
            ) @include(if: $isFilllevelmeasurementSet) {
              edges {
                node {
                  id
                  fillPercentage
                  fillPercentageMasked
                  createdAt
                  rawMeasurements
                }
              }
            }
            notificationSet(last: 10)
              @include(if: $isFillleveldeviceSetNotificationSet) {
              edges {
                node {
                  id
                  status
                  message
                  createdAt
                }
              }
            }
            device {
              id
              devId
              deviceType @include(if: $isDeviceToContainerSetDeviceDeviceType) {
                id
                name
              }
              connectionType
              ... on LorawanDeviceType {
                appKey
              }
              ... on MobileDeviceType {
                simcard {
                  id
                  iccid
                  imsi
                  apn
                  isActive
                }
              }
              ... on SigFoxDeviceType {
                sigFoxPac {
                  id
                  pac
                }
              }
            }
          }
        }
      }
      containerLog @include(if: $isContainerLog) {
        id
        oldFillPercentage
        createdAt
      }
      pickupSetting {
        name
        id
      }
      sensorFillLevel
      sensorFillLevelPeriodHours
    }
  }
`

export const GET_ALL_CONTAINERS = gql`
  ${FrgWasteFraction}
  ${FrgVehicleForLink}
  query GetContainersNewAllContainers(
    $last: Int
    $first: Int
    $before: String
    $after: String
    $q: String
    $order: String
    $orderBy: String
    $measurement_FillPercentage: String
    $activeProjects: String
    $offset: Int
    $containerTypes: [ID]
    $pickupSetting: [ID]
    $sensor_Isnull: Boolean
    $name: String
    $containerType_Name_In: [String]
    $search: String
    $wasteFractionIds: String
    $propertyIds: [ID]
    $propertyGroup: [ID]
    $status: [String]
    $from: DateTime
    $to: DateTime
    $containerGroupIds: [ID]
    $collectionCalendars: [ID]
    $routeSchemes: [ID]
    $vehicles: [ID]
    $address: String
    $containerId: String
    $propertyNumber: String
    $ids: [ID]
    $propertyTypeIds: [ID]
    $noProperty: Boolean
  ) {
    allContainers(
      last: $last
      first: $first
      after: $after
      before: $before
      q: $q
      order: $order
      orderBy: $orderBy
      measurement_FillPercentage: $measurement_FillPercentage
      activeProjects: $activeProjects
      offset: $offset
      containerTypes: $containerTypes
      pickupSetting: $pickupSetting
      sensor_Isnull: $sensor_Isnull
      name: $name
      containerType_Name_In: $containerType_Name_In
      search: $search
      propertyIds: $propertyIds
      wasteFractionIds: $wasteFractionIds
      status: $status
      From: $from
      To: $to
      propertyGroupIds: $propertyGroup
      containerGroupIds: $containerGroupIds
      collectionCalendars: $collectionCalendars
      routeSchemes: $routeSchemes
      vehicles: $vehicles
      address: $address
      containerId: $containerId
      propertyNumber: $propertyNumber
      ids: $ids
      propertyTypeIds: $propertyTypeIds
      noProperty: $noProperty
    ) {
      totalCount
      edges {
        node {
          id
          containerId
          description
          status
          totalOverflowings
          scheduledPickup
          showOnRoute
          pickupMethod
          latestPickUp
          nextPickUp
          pickupSetting {
            id
            name
          }
          wasteFraction {
            ...FrgWasteFraction
            wasteSubstance
            wasteCategory
          }
          project {
            id
            name
          }
          location {
            id
            name
          }
          containerType {
            id
            name
          }
          measurement {
            id
            fillLevel
            fillPercentage
            batteryPercentage
            rsi
            createdAt
            containerDevice {
              id
              device {
                id
                devId
              }
            }
          }
          metaData
          containerGroup {
            id
            name
          }
          deviceToContainerSet {
            edges {
              node {
                startDate
                endDate
                device {
                  id
                  devId
                }
                measurementSettings {
                  id
                  name
                }
              }
            }
          }
          collectionCalendar {
            id
            name
          }
          activeAgreement {
            property {
              externalPropertyNumber
              id
              type {
                id
                name
              }
              location {
                id
                name
              }
            }
          }
          routeScheme {
            id
            name
          }
          vehicle {
            ...FrgVehicleForLink
          }
        }
      }
    }
  }
`

export const GET_FILL_LEVEL_MEASUREMENTS_BY_ID = gql`
  query GetFillLevelMeasurementsById($id: ID!) {
    fillLevelMeasurements(id: $id) {
      id
      rawMeasurements
      rawMeasurementsPercentage
    }
  }
`

export const DELETE_CONTAINER = gql`
  mutation DeleteContainer($containerId: ID!) {
    deleteContainer(containerId: $containerId) {
      status
    }
  }
`
