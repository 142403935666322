import React, { useState, FC } from 'react'
import { createPortal } from 'react-dom'
import { AlertProps } from 'antd'
import { produce } from 'immer'
import uniqueId from 'lodash/uniqueId'

import { TConfirmProps, Confirm } from 'src/storybook/components/Confirm'
import Modal, { IModal } from 'src/storybook/components/Modal'
import { useModelState } from 'src/storybook/components/ModalWithButton'

import store from './store'

type AsyncActionModalProps = {
  modalProps: Omit<
    IModal,
    'confirmLoading' | 'visible' | 'onClose' | 'variant'
  > & {
    onClose?: IModal['onClose']
  }
  /** @deprecated - use confirm props instead */
  alertProps?: Pick<AlertProps, 'description'> & {
    /** @deprecated - will always show icon */
    showIcon?: boolean
    type: Exclude<AlertProps['type'], 'success'>
    // deprecate message
    /** @deprecated - pass modalProps.title for title */
    message?: AlertProps['message']
  }
  confirmProps?: Pick<
    TConfirmProps,
    'title' | 'children' | 'cancelButtonProps' | 'submitButtonProps' | 'variant'
  >
  checkboxProps?: TConfirmProps['checkboxProps']
  // TODO: fix $TSFixMe
  asyncCall: (args: {
    closeModal: () => void
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    checked?: boolean
  }) => Promise<$TSFixMe>
  _internal: {
    destory: () => void
  }
}

// map alert variant to modal variant
const alertToConfirmVariant: Record<
  Exclude<AlertProps['type'], undefined>,
  IModal['variant']
> = {
  info: 'default',
  warning: 'warning',
  error: 'danger',
  success: 'default',
}

const AsyncActionModal: FC<AsyncActionModalProps> = ({
  alertProps,
  confirmProps,
  checkboxProps,
  modalProps,
  asyncCall,
  _internal,
}) => {
  // variant will be from alertProps.type otherwise confirmProps.variant
  const variant =
    (alertProps?.type
      ? alertToConfirmVariant[alertProps.type]
      : confirmProps?.variant) || 'default'
  const [{ visible }, { closeModal }] = useModelState({ init: true })
  const [loading, setLoading] = useState(false)

  return (
    <Modal
      {...modalProps}
      visible={visible}
      title={modalProps.title}
      onClose={() => {
        closeModal()
        modalProps?.onClose?.()
      }}
      afterClose={() => {
        modalProps?.afterClose?.()
        _internal.destory()
      }}
      okText={modalProps.okText}
      okType={modalProps.okType}
      confirmLoading={loading}
      width={modalProps.width}
      variant={variant}
      footer={null}
    >
      {/*  // Render the Confirm component but map the alertProps to the Confirm component and also add the asyncCall function to the submit button and use closeModal on cancel button */}
      {alertProps && (
        <Confirm
          variant={variant}
          title={alertProps.description}
          // cancel button will close the modal
          cancelButtonProps={{
            onClick: closeModal,
          }}
          submitButtonProps={{
            onClick: async () => {
              setLoading(true)
              await asyncCall({ closeModal, setLoading })
            },
            loading,
          }}
          checkboxProps={checkboxProps}
        >
          <></>
        </Confirm>
      )}
      {/*  // Render the Confirm component if no alertProps are provided,
        // We need to the children as function in the Confrim component and in the submit button we need to call the asyncCall function and use closeModal on cancel button */}
      {confirmProps && (
        <Confirm
          {...confirmProps}
          variant={variant}
          title={confirmProps?.title}
          // cancel button will close the modal
          cancelButtonProps={{
            ...confirmProps?.cancelButtonProps,
            onClick: closeModal,
          }}
          submitButtonProps={{
            ...confirmProps?.submitButtonProps,
            onClick: async (checked) => {
              setLoading(true)
              await asyncCall({ closeModal, setLoading, checked })
            },
            loading,
          }}
          checkboxProps={checkboxProps}
        />
      )}
    </Modal>
  )
}

export type AsyncAlertActionArgs = Omit<AsyncActionModalProps, '_internal'>

const asyncAlertAction = (props: AsyncAlertActionArgs) => {
  const { setState } = store
  const myId = uniqueId()
  const div = document.createElement('div')
  document.body.appendChild(div)

  function destory() {
    setState(
      produce((draft) => {
        delete draft.jsxMap[myId]
      })
    )
    if (div.parentNode) {
      div.parentNode.removeChild(div)
    }
  }

  setState(
    produce((draft) => {
      draft.jsxMap[myId] = createPortal(
        <AsyncActionModal
          _internal={{
            destory,
          }}
          {...props}
        />,
        div
      )
    })
  )
}

export default asyncAlertAction
