import Color from 'color'
import stringToColor from 'string-to-color'

import {
  blue600,
  cyan600,
  gray700,
  green600,
  orange600,
  purple600,
  red600,
  yellow600,
} from 'src/styles/color-theme'
import findClosestColorMatch from 'src/utils/findClosestColorMatch'

export type AvatarColorType =
  | 'red'
  | 'green'
  | 'blue'
  | 'purple'
  | 'yellow'
  | 'gray'
  | 'orange'
  | 'cyan'

const AVATAR_COLORS = {
  red: red600,
  green: green600,
  blue: blue600,
  purple: purple600,
  yellow: yellow600,
  gray: gray700,
  orange: orange600,
  cyan: cyan600,
}

export function checkIsPresetColor(
  color: AvatarColorType | string
): color is AvatarColorType {
  const isPreset = [
    'red',
    'green',
    'blue',
    'purple',
    'yellow',
    'orange',
    'cyan',
    'gray',
  ].includes(color)
  if (isPreset) {
    return true
  }

  try {
    const col = Color(color)
    return !col
  } catch (e) {
    return true
  }
}

export function getBaseColor(color?: AvatarColorType | string, text?: string) {
  if (!color) {
    return findClosestColorMatch(
      stringToColor(text || ''),
      Object.values(AVATAR_COLORS)
    )
  }

  const isPreset = checkIsPresetColor(color)
  return isPreset ? AVATAR_COLORS[color] : color
}

export function generateColorStyles(
  color?: AvatarColorType | string,
  text?: string
) {
  const modifiedColor = getBaseColor(color, text)
  return {
    color: Color(modifiedColor).darken(0.6).hex(),
    backgroundColor: Color(modifiedColor).lighten(0.7).hex(),
  }
}
