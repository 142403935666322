import React from 'react'
import { T } from '@transifex/react'

import { createCreateBulkRoutesMap } from './create-bulk'
import { createCustomerInboxMap } from './customer-inbox'
import { createTicketDraftsRouteMap } from './drafts'
import { createEditBulkRoutesMap } from './edit-bulk'
import { createDetailRoutesMap } from './ticket-details'
import { pathJoin } from '../../../utils'

export const createTicketsRouteMap = (prefix: string) => ({
  '/:id': (id: string) =>
    pathJoin(prefix)('id')(id).chainMapAuto(createDetailRoutesMap, {
      title: <T _str="Ticket Details" />,
      breadCrumbTitle: <T _str="Details" />,
    }),
  kanban: pathJoin(prefix)('kanban').chainWithTitle({
    title: <T _str="Ticket kanban" />,
    breadCrumbTitle: <T _str="Ticket kanban" />,
  }),
  map: pathJoin(prefix)('map').chainWithTitle({
    title: <T _str="Ticket map" />,
    breadCrumbTitle: <T _str="Ticket map" />,
  }),
  drafts: pathJoin(prefix)('drafts').chainMapAuto(createTicketDraftsRouteMap, {
    title: <T _str="Ticket Drafts" />,
    breadCrumbTitle: <T _str="Ticket Drafts" />,
  }),
  'create-bulk': pathJoin(prefix)('create-bulk').chainMapAuto(
    createCreateBulkRoutesMap,
    {
      title: <T _str="Create Bulk Tickets" />,
      breadCrumbTitle: <T _str="Create Bulk" />,
    }
  ),
  'edit-bulk': pathJoin(prefix)('edit-bulk').chainMapAuto(
    createEditBulkRoutesMap,
    {
      title: <T _str="Edit Bulk Tickets" />,
      breadCrumbTitle: <T _str="Edit Bulk" />,
    }
  ),
  'customer-inbox': pathJoin(prefix)('customer-inbox').chainMapAuto(
    createCustomerInboxMap,
    {
      title: <T _str="Customer inbox" />,
      breadCrumbTitle: <T _str="Customer inbox" />,
    }
  ),
})
