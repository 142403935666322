/* eslint-disable react/destructuring-assignment */
import React, { FC, ReactNode } from 'react'
import { Col, Popover, PopoverProps, Row, Tag, TagProps } from 'src/antd'
import styled, { CSSProperties } from 'styled-components'

import OverflowList from './OverflowList'

const PopoverWithOverlayClassname = ({
  className,
  ...props
}: PopoverProps & { className?: string }) => (
  <Popover {...props} overlayClassName={className} />
)

const StyledPopover = styled(PopoverWithOverlayClassname)`
  .ant-popover-inner-content {
    padding: 8px 6px;
  }
`

export type TagOption =
  | {
      label: ReactNode
      color?: TagProps['color']
      render?: () => ReactNode
    }
  | {
      label?: ReactNode
      color?: TagProps['color']
      render: () => ReactNode
    }

type TTag = string | ReactNode | TagOption

const isTagOption = (obj: TTag): obj is TagOption => {
  return (
    (obj as TagOption)?.label !== undefined ||
    (obj as TagOption)?.render !== undefined
  )
}

export type TagsMultipleProps = {
  /** tags to render. Can be an array with string or an object having label */
  tags?: TTag[]
  /** Maximum number of tags which will be displayed not in popover */
  maxTagsToShow?: number | 'responsive'
}

const Item: FC<{ tag: TTag; style?: CSSProperties }> = ({ tag, style }) => {
  return (
    <>
      {isTagOption(tag) && tag?.render ? (
        <>{tag?.render()}</>
      ) : (
        <Tag
          style={{ margin: 0, ...style }}
          color={isTagOption(tag) ? tag.color : undefined}
        >
          {isTagOption(tag) ? tag.label : tag}
        </Tag>
      )}
    </>
  )
}

const Overflow: FC<{ tags: TTag[] }> = ({ tags }) => {
  return (
    <StyledPopover
      content={
        <Row style={{ maxWidth: 200 }} gutter={[6, 6]}>
          {tags?.map((tag, index) => (
            <Col key={index}>
              <Item tag={tag} />
            </Col>
          ))}
        </Row>
      }
    >
      <Tag>{`+${tags.length}...`}</Tag>
    </StyledPopover>
  )
}

const ItemRenderer = (item: TTag, index: number) => {
  return <Item style={{ marginRight: '0.5rem' }} tag={item} key={index} />
}

const OverflowRenderer = (items: TTag[]) => {
  return <Overflow tags={items} />
}

export const TagsMultiple: React.FC<TagsMultipleProps> = ({
  tags = [],
  maxTagsToShow = 1,
  children,
}) => {
  if (maxTagsToShow === 'responsive') {
    return (
      <OverflowList
        collapseFrom="end"
        minVisibleItems={1}
        items={tags}
        itemRenderer={ItemRenderer}
        overflowRenderer={OverflowRenderer}
      />
    )
  }

  const tagsToShow = tags.slice(0, maxTagsToShow)
  const otherTags = tags.slice(maxTagsToShow)

  return (
    <Row gutter={[8, 8]}>
      {tagsToShow.map((tag, index) => (
        <Col key={index}>
          <Item tag={tag} key={index} />
        </Col>
      ))}
      {!!otherTags?.length && (
        <Col>
          <Overflow tags={otherTags} />
        </Col>
      )}
      {children}
    </Row>
  )
}
