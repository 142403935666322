import React from 'react'
import { CalendarOutlined, CaretDownOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'
import { RangePickerProps } from 'antd/lib/date-picker'
import dayjs, { Dayjs } from 'dayjs'
import { Tag, Divider, Select, Space, DatePicker, Row, Col } from 'src/antd'

import { Opt } from 'src/new-components'
import { FilterMenuButton } from 'src/new-components/filter-menu-button'
import { gray300 } from 'src/styles/color-theme'

import {
  StyledDatePickerWrapper,
  StyledPopover,
  StyledRadioGroup,
} from './styles'
import { ActionButtons, MenuActions } from '../ActionButtons'
import { FromTo } from '../FromTo'
import {
  TQuickDateOption,
  getQuickDateSelectValue,
  quickDateOptionMap,
  quickDateOptions,
  quickDateOptionsLabelMap,
} from '../RangeSelectorWithRadio/utils'
import { Typography } from '../Typography'

const getDateRangeByTagColor = (dateRangeBy: string) => {
  switch (dateRangeBy) {
    case 'created_at':
      return 'orange'
    case 'status_completed_at':
      return 'green'
    case 'status_inprogress_at':
      return 'blue'
    default:
      return 'gray'
  }
}

const { RangePicker } = DatePicker

type TValue = {
  dateRange: [Dayjs, Dayjs] | null
  dateRangeBy: string
}

type TFilterMenuDatePickerProps = Omit<
  RangePickerProps,
  'value' | 'onChange'
> & {
  value: TValue
  onChange: (value: TValue) => void
  dateRangeByOptions?: Opt[]
}

const quickSelectOptions = quickDateOptions.filter(
  (opt) => opt.value !== 'none'
)

const firstFiveOrderList: TQuickDateOption[] = [
  'today',
  'custom',
  'last_7_days',
  'this_month',
  'last_90_days',
]
const firstFiveQuickSelectOptions = quickSelectOptions.filter((opt) =>
  firstFiveOrderList.includes(opt.value)
)
const restQuickSelectOptions = quickSelectOptions.filter(
  (opt) => !firstFiveOrderList.includes(opt.value)
)

export function FilterMenuDatePicker({
  value,
  onChange,
  dateRangeByOptions,
}: TFilterMenuDatePickerProps) {
  const selectedCreatedBy = dateRangeByOptions?.find(
    (option) => option.value === value.dateRangeBy
  )?.label

  const quickSelectValue = value.dateRange
    ? getQuickDateSelectValue(value.dateRange)
    : null

  const onQuickSelectChange = (v: TQuickDateOption) => {
    const dateRange = quickDateOptionMap[v]()
    onChange?.({ ...value, dateRange })
  }

  const getQuickFormattedValue = () => {
    if (!value.dateRange) return null

    if (quickSelectValue === 'custom') {
      return `${dayjs(value.dateRange[0]).format('MMM DD YYYY')} - ${dayjs(
        value.dateRange[1]
      ).format('MMM DD YYYY')}`
    }

    return quickSelectValue ? quickDateOptionsLabelMap[quickSelectValue] : null
  }

  const formattedValue = getQuickFormattedValue()

  const isCreateByEnabled = dateRangeByOptions && dateRangeByOptions.length > 0

  const rangeCountInDays = value.dateRange
    ? value.dateRange[1].diff(value.dateRange[0], 'days') + 1
    : 0

  return (
    <div>
      <StyledPopover
        placement="bottomLeft"
        trigger={['click']}
        content={
          <div style={{ width: 660 }}>
            <div style={{ padding: '6px 12px' }}>
              <Space size={2} style={{ width: '100%' }} align="center">
                {isCreateByEnabled && (
                  <>
                    <Select
                      className="quick-select"
                      suffixIcon={<CaretDownOutlined />}
                      options={dateRangeByOptions}
                      value={value.dateRangeBy}
                      onChange={(dateRangeBy: string) =>
                        onChange({ ...value, dateRangeBy })
                      }
                      popupMatchSelectWidth={false}
                      style={{ width: 160 }}
                      getPopupContainer={() => document.body}
                    />
                    <Divider type="vertical" style={{ height: 40 }} />
                  </>
                )}
                <StyledRadioGroup
                  options={
                    isCreateByEnabled
                      ? firstFiveQuickSelectOptions
                      : quickSelectOptions
                  }
                  value={quickSelectValue}
                  onChange={(e) => onQuickSelectChange(e.target.value)}
                  optionType="button"
                  buttonStyle="solid"
                />
                {isCreateByEnabled && (
                  <ActionButtons
                    dropdownOnly
                    actions={[
                      ...restQuickSelectOptions.map(
                        (option) =>
                          [
                            option.label,
                            () => onQuickSelectChange(option.value),
                          ] as MenuActions
                      ),
                    ]}
                  />
                )}
              </Space>
            </div>
            <div style={{ backgroundColor: gray300, padding: '9px 16px' }}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Typography
                    weight="semi-bold"
                    style={{ marginRight: 16, zIndex: 1 }}
                  >
                    <T _str="Date" />
                  </Typography>
                  <FromTo
                    short
                    from={value.dateRange?.[0]?.format('MMM DD YYYY')}
                    to={value.dateRange?.[1]?.format('MMM DD YYYY')}
                  />
                </Col>
                <Col>
                  <Typography type="secondary">
                    <T _str="{count} days" count={rangeCountInDays} />
                  </Typography>
                </Col>
              </Row>
            </div>
            <StyledDatePickerWrapper>
              <RangePicker
                open
                value={value.dateRange}
                onChange={(dateRange) =>
                  onChange({
                    ...value,
                    dateRange: dateRange as [Dayjs, Dayjs],
                  })
                }
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
              />
            </StyledDatePickerWrapper>
          </div>
        }
      >
        <FilterMenuButton
          withCaretIcon
          icon={<CalendarOutlined style={{ position: 'relative', top: 1 }} />}
        >
          {selectedCreatedBy && (
            <Tag
              color={getDateRangeByTagColor(value.dateRangeBy)}
              style={{ marginRight: 2 }}
            >
              {selectedCreatedBy}
            </Tag>
          )}
          {formattedValue && <Tag style={{ margin: 0 }}>{formattedValue}</Tag>}
        </FilterMenuButton>
      </StyledPopover>
    </div>
  )
}
