import { gql } from '@apollo/client'

import { FrgDepotForLink } from 'src/api'

export const GET = gql`
  ${FrgDepotForLink}
  query GetLocationInfoPopover($id: ID!) {
    depot(id: $id) {
      ...FrgDepotForLink
      depotType
      location {
        id
        name
      }
      openingTime
      closingTime
      contactPerson {
        name
      }
    }
  }
`
