import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { createOptionsFromLabelMap } from 'src/utils/create-options'

export type ContainerSensorType = 'with' | 'without' | 'all'

export const containerSensorLabelMap: Record<ContainerSensorType, ReactNode> = {
  without: <T _str="Without sensor" />,
  with: <T _str="With sensor" />,
  all: <T _str="Show all" />,
}

export const containerSensorOptions = createOptionsFromLabelMap(
  containerSensorLabelMap
)

export const containerSensorFilterMap: Record<
  ContainerSensorType,
  boolean | undefined
> = {
  all: undefined,
  with: false,
  without: true,
}
