import { gql, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import {
  GetInProgressBackgroundJobs,
  GetInProgressBackgroundJobsVariables,
  MarkBackgroundJobRead,
  MarkBackgroundJobReadVariables,
} from './__generated__/types'

const ExportHistoryDef = gql`
  fragment ExportHistoryDef on ExportHistoryDef {
    id
    export {
      id
      exportType
    }
    startedAt
    completedAt
    progress
    exportStatus: status
    totalItems
  }
`

const ImportHistoryDef = gql`
  fragment ImportHistoryDef on ImportHistoryDef {
    id
    startedAt
    completedAt
    progress
    importType
    importStatus: status
    fileUrl
    importFile
    importAction
    totalItems
    createItems
    updateItems
  }
`

const BulkActionProgressDef = gql`
  fragment BulkActionProgressDef on BulkActionProgressDef {
    id
    createdAt
    startedAt
    completedAt
    name
    progress
    total
    note
    status
    startedAt
    completedAt
    startedAt
  }
`

const ContainerBarcodeGenerationHistoryDef = gql`
  fragment ContainerBarcodeGenerationHistoryDef on ContainerBarcodeGenerationHistoryDef {
    id
    createdAt: created
    fileUrl
    progress
    barcodeStatus: status
    completedAt
    total
  }
`

const FrgBackgroundJob = gql`
  ${ExportHistoryDef}
  ${ImportHistoryDef}
  ${BulkActionProgressDef}
  ${ContainerBarcodeGenerationHistoryDef}
  fragment FrgBackgroundJob on UserJobNotificationType {
    id
    createdAt
    event {
      ... on ExportHistoryDef {
        ...ExportHistoryDef
      }
      ... on ImportHistoryDef {
        ...ImportHistoryDef
      }
      ... on BulkActionProgressDef {
        ...BulkActionProgressDef
      }
      ... on ContainerBarcodeGenerationHistoryDef {
        ...ContainerBarcodeGenerationHistoryDef
      }
    }
  }
`

export const GET_ALL_BACKGROUND_JOBS = gql`
  ${FrgBackgroundJob}
  query GetAllBackgroundJobs(
    $first: Int
    $offset: Int
    $orderBy: String
    $order: String
    $unread: Boolean
    $createdAtFrom: DateTime
    $progress: [String]
  ) {
    allUserJobNotifications(
      first: $first
      orderBy: $orderBy
      order: $order
      unread: $unread
      offset: $offset
      createdAtFrom: $createdAtFrom
      progress: $progress
    ) {
      totalCount
      edges {
        node {
          ...FrgBackgroundJob
        }
      }
    }
  }
`

export const GET_IN_PROGRESS_BACKGROUND_JOBS_COUNT = gql`
  query GetInProgressBackgroundJobs($createdAtFrom: DateTime) {
    allUserJobNotifications(
      progress: ["in_progress"]
      first: 0
      createdAtFrom: $createdAtFrom
    ) {
      totalCount
    }
  }
`

export const ON_USER_JOB_NOTIFICATION = gql`
  ${FrgBackgroundJob}
  subscription OnUserJobNotification {
    onUserJobNotification {
      userJobNotification {
        ...FrgBackgroundJob
      }
    }
  }
`

export const MARK_BACKGROUND_JOB_READ = gql`
  mutation MarkBackgroundJobRead($id: ID!) {
    markUserJobNotificationRead(id: $id) {
      userJobNotification {
        id
      }
    }
  }
`

export const useInProgressBackgroundJobs = () => {
  const { data, ...rest } = useQuery<
    GetInProgressBackgroundJobs,
    GetInProgressBackgroundJobsVariables
  >(GET_IN_PROGRESS_BACKGROUND_JOBS_COUNT, {
    variables: {
      createdAtFrom: dayjs().subtract(6, 'days').startOf('day').toISOString(),
    },
  })
  return { data: data?.allUserJobNotifications?.totalCount || 0, ...rest }
}

export const useMarkBackgroundJob = () => {
  return useMutation<MarkBackgroundJobRead, MarkBackgroundJobReadVariables>(
    MARK_BACKGROUND_JOB_READ
  )
}
