import React, { Fragment } from 'react'
import { useStore } from 'zustand'

import asyncAlertAction, { AsyncAlertActionArgs } from './asyncAlert'
import asyncModal, { ResolveReject, TAsyncModalArgs } from './asyncModal'
import store from './store'

export type { AsyncAlertActionArgs, ResolveReject, TAsyncModalArgs }

export { asyncAlertAction, asyncModal }

export default function AsyncModalPortal() {
  const jsxMap = useStore(store, (str) => str.jsxMap)

  return (
    <>
      {Object.values(jsxMap).map((jsx, i) => (
        <Fragment key={i}>{jsx}</Fragment>
      ))}
    </>
  )
}
