import { useEffect } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ar'
import 'dayjs/locale/da'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/nb'
import 'dayjs/locale/sv'
import { kebabCase } from 'lodash'

import { CoreIoUserDatetimeFormatLocaleChoices } from 'src/gen-types'

import { locale12Formats, locale24Formats } from './formats'
import { useMe } from '../../hooks'

dayjs.locale('en')

const locales = ['en', 'en-gb', 'da', 'nb', 'sv', 'ar']

/**
 * Set dayjs locale based on user setting.
 */
const DayjsI18n = () => {
  const me = useMe()

  useEffect(() => {
    const locale: CoreIoUserDatetimeFormatLocaleChoices =
      me?.datetimeFormatLocale
    const userLocale = kebabCase(locale)
    const isClock12 = me?.company?.settings?.clockFormat12
    if (me && userLocale) {
      if (locales.includes(userLocale)) {
        dayjs.locale(userLocale)
        dayjs.updateLocale(userLocale, {
          formats: isClock12
            ? locale12Formats[locale]
            : locale24Formats[locale],
        })
      } else {
        // eslint-disable-next-line no-console
        console.log(
          new Error(
            `User date format setting: ${userLocale} is not supported by dayjs`
          )
        )
      }
    }
  }, [me])

  return null
}

export default DayjsI18n
