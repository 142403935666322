import React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Avatar as AntdAvatar, AvatarProps as AntdAvatarProps } from 'antd'
import isNumber from 'lodash/isNumber'
import styled from 'styled-components'

import { gray700 } from 'src/styles/color-theme'
import replaceStringWithLetters from 'src/utils/stringToLetters'

import { AvatarColorType, generateColorStyles } from './utils'

// TODO: improve text color with luminance when IPFE-7866 would be merged
const StyledAvatar = styled(AntdAvatar)<{
  $text?: string
  $color?: AvatarColorType | string
}>(({ src, size, $text, $color }) => {
  if (src) {
    return {}
  }

  const colorStyles = generateColorStyles($color, $text)
  return `
    background-color: ${colorStyles.backgroundColor};
    color: ${colorStyles.color};
    .ant-avatar-string {
      font-weight: 500;
      font-size: ${isNumber(size) ? size * 0.45 : 16}px !important;
    }
    .anticon {
      color: ${gray700};
    }
  `
})

export type AvatarProps = {
  text?: string
  color?: AvatarColorType | string
  userIconByDefault?: boolean
} & AntdAvatarProps

function Avatar({
  text,
  color,
  userIconByDefault = true,
  src,
  ...props
}: AvatarProps) {
  return (
    <StyledAvatar
      {...props}
      src={src || undefined}
      $color={color}
      $text={text}
      icon={props.icon || (userIconByDefault && !text && <UserOutlined />)}
    >
      {typeof text === 'string' ? replaceStringWithLetters(text) : text}
    </StyledAvatar>
  )
}

Avatar.Group = AntdAvatar.Group

export { Avatar }
