import React from 'react'
import { DatePicker as BaseDatePicker, DatePickerProps } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'

import { object } from 'src/utils/object'

import { getDatePickerBaseProps } from './DatePickerBase'

const { RangePicker: BaseRangePicker } = BaseDatePicker

type TWhDatePickerProps = Omit<DatePickerProps, 'dateRender'>

const WhDatePicker = ({
  onChange,
  popupClassName,
  ...props
}: TWhDatePickerProps) => {
  const { showTime } = props

  return (
    <BaseDatePicker
      {...object(!showTime && { format: 'LL' })}
      getPopupContainer={(trigger) => trigger.parentElement!}
      {...props}
      {...getDatePickerBaseProps({ popupClassName })}
      onChange={(dayjsDate, dateString) => {
        onChange?.(
          dayjsDate,
          // eslint-disable-next-line no-nested-ternary
          showTime
            ? dateString
            : dayjsDate
            ? dayjs(dayjsDate).format('YYYY-MM-DD')
            : ''
        )
      }}
    />
  )
}

type TWHRangePickerProps = Omit<RangePickerProps, 'dateRender'> & {
  showTime?: boolean
}

const WhRangePicker = ({
  onChange,
  popupClassName,
  ...props
}: TWHRangePickerProps) => {
  const { showTime } = props
  return (
    <BaseRangePicker
      {...object(!showTime && { format: 'LL' })}
      {...props}
      {...getDatePickerBaseProps({ popupClassName })}
      onChange={(dayjsDate, dateString) => {
        onChange?.(
          dayjsDate,
          // eslint-disable-next-line no-nested-ternary
          showTime
            ? dateString
            : dayjsDate
            ? [
                dayjs(dayjsDate?.[0]).format('YYYY-MM-DD'),
                dayjs(dayjsDate?.[1]).format('YYYY-MM-DD'),
              ]
            : ['', '']
        )
      }}
    />
  )
}

WhDatePicker.RangePicker = WhRangePicker

const DATE_PICKER_FORMAT = 'LL'
const DATE_PICKER_FORMAT_TIME = 'LLL'

WhDatePicker.FORMATS = { DATE_PICKER_FORMAT, DATE_PICKER_FORMAT_TIME }

export { WhDatePicker }
