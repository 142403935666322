import { History } from 'history'
import queryString from 'query-string'

import { userVar } from 'src/reactive-variables'

import { routingMap } from '../reducers/routing/routing-map'

export const checkForRedirects = (history: History) => {
  const clientState: $TSFixMe = userVar()
  const authorized = clientState?.id
  const qs = queryString.parse(history.location.search)
  // redirection otherwise just return false
  if (qs?.company_email_account === 'true' && authorized) {
    // Company email account redirect is being used.
    // keep the query string and redirect more.
    history.replace({
      pathname: routingMap.app.settings['company/:id'](clientState?.company.id)[
        'email-accounts'
      ]._,
      search: history.location.search,
    })
  }
  return false
}
