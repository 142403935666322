import { gql } from '@apollo/client'

import { FrgVehicleForLink } from 'src/api'

export const GET = gql`
  ${FrgVehicleForLink}
  query GetVehicleInfoPopover($id: ID!) {
    vehicle(id: $id) {
      ...FrgVehicleForLink
      status
      vehicleType {
        id
        name
      }
      activeDepot {
        id
        name
      }
    }
  }
`
