import { CoreIoUserDatetimeFormatLocaleChoices } from 'src/gen-types'

const EN12Formats = {
  LT: 'h:mm A',
  LTS: 'h:mm:ss A',
  L: 'MM/DD/YYYY',
  LL: 'MMMM D, YYYY',
  LLL: 'MMMM D, YYYY h:mm A',
  LLLL: 'dddd, MMMM D, YYYY h:mm A',
  l: 'M/D/YYYY',
  ll: 'MMM D, YYYY',
  lll: 'MMM D, YYYY h:mm A',
  llll: 'dddd, MMMM D, YYYY h:mm A',
}

const EN24Formats = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'MM/DD/YYYY',
  LL: 'MMMM D, YYYY',
  LLL: 'MMMM D, YYYY HH:mm',
  LLLL: 'dddd, MMMM D, YYYY HH:mm',
  l: 'M/D/YYYY',
  ll: 'MMM D, YYYY',
  lll: 'MMM D, YYYY HH:mm',
  llll: 'dddd, MMMM D, YYYY HH:mm',
}

const ENGB12Formats = {
  LT: 'h:mm A',
  LTS: 'h:mm:ss A',
  L: 'DD/MM/YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY h:mm A',
  LLLL: 'dddd, D MMMM YYYY h:mm A',
}

const ENGB24Formats = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'DD/MM/YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY HH:mm',
  LLLL: 'dddd, D MMMM YYYY HH:mm',
}

const DA12Formats = {
  LT: 'h:mm A',
  LTS: 'h:mm:ss A',
  L: 'DD.MM.YYYY',
  LL: 'D. MMMM YYYY',
  LLL: 'D. MMMM YYYY h:mm A',
  LLLL: 'dddd [d.] D. MMMM YYYY [kl.] h:mm A',
}

const DA24Formats = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'DD.MM.YYYY',
  LL: 'D. MMMM YYYY',
  LLL: 'D. MMMM YYYY HH:mm',
  LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
}

const NB12Formats = {
  LT: 'h:mm A',
  LTS: 'h:mm:ss A',
  L: 'DD.MM.YYYY',
  LL: 'D. MMMM YYYY',
  LLL: 'D. MMMM YYYY [kl.] h:mm A',
  LLLL: 'dddd [d.] MMMM YYYY [kl.] h:mm A',
}

const NB24Formats = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'DD.MM.YYYY',
  LL: 'D. MMMM YYYY',
  LLL: 'D. MMMM YYYY [kl.] HH:mm',
  LLLL: 'dddd [d.] MMMM YYYY [kl.] HH:mm',
}

const SV12Formats = {
  LT: 'h:mm A',
  LTS: 'h:mm:ss A',
  L: 'YYYY-MM-DD',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY [kl.] h:mm A',
  LLLL: 'dddd D MMMM YYYY [kl.] h:mm A',
  lll: 'D MMM YYYY h:mm A',
  llll: 'ddd D MMM YYYY h:mm A',
}

const SV24Formats = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'YYYY-MM-DD',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY [kl.] HH:mm',
  LLLL: 'dddd D MMMM YYYY [kl.] HH:mm',
  lll: 'D MMM YYYY HH:mm',
  llll: 'ddd D MMM YYYY HH:mm',
}

const AR12Formats = {
  LT: 'h:mm A',
  LTS: 'h:mm:ss A',
  L: 'D/M/YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY h:mm A',
  LLLL: 'dddd D MMMM YYYY h:mm A',
}

const AR24Formats = {
  LT: 'HH:mm',
  LTS: 'HH:mm:ss',
  L: 'D/M/YYYY',
  LL: 'D MMMM YYYY',
  LLL: 'D MMMM YYYY HH:mm',
  LLLL: 'dddd D MMMM YYYY HH:mm',
}

export const locale12Formats: Record<
  CoreIoUserDatetimeFormatLocaleChoices,
  Record<string, string>
> = {
  EN: EN12Formats,
  EN_GB: ENGB12Formats,
  DA: DA12Formats,
  NB: NB12Formats,
  SV: SV12Formats,
  AR: AR12Formats,
}

export const locale24Formats: Record<
  CoreIoUserDatetimeFormatLocaleChoices,
  Record<string, string>
> = {
  EN: EN24Formats,
  EN_GB: ENGB24Formats,
  DA: DA24Formats,
  NB: NB24Formats,
  SV: SV24Formats,
  AR: AR24Formats,
}
