import React, { FC } from 'react'
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'

import useDelayQuery from 'src/hooks/useDelayQuery'
import { useRoutingHistory } from 'src/reducers/routing/child-router-factory'
import { TagOption } from 'src/storybook/components'
import { array } from 'src/utils/array'
import getFullName from 'src/utils/getFullName'

import {
  GetUserInfoPopover,
  GetUserInfoPopoverVariables,
} from './__generated__/types'
import { GET } from './api'
import { PopupContentProps } from '..'
import InfoCard from '../components/InfoCard'

const UserPopup: FC<PopupContentProps> = ({ id }) => {
  const [history, { routingMap }] = useRoutingHistory()
  const { data: { user } = {}, loading } = useDelayQuery<
    GetUserInfoPopover,
    GetUserInfoPopoverVariables
  >(
    GET,
    {
      variables: {
        id,
      },
    },
    300
  )

  const role = user?.companyRole?.name
  const isMaster = user?.isMaster

  return (
    <InfoCard
      title={getFullName(user)}
      avatarProps={{
        src: user?.logo,
        color: user?.color,
      }}
      headIcon={<UserOutlined />}
      headText={<T _str="Users" />}
      data={array(
        !!user?.phoneNumber && {
          icon: <PhoneOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />,
          label: user?.phoneNumber,
        },
        !!user?.email && {
          icon: <MailOutlined style={{ color: 'rgba(38, 38, 38, 0.5)' }} />,
          label: user?.email,
        }
      )}
      tags={array<TagOption | string>(
        isMaster && {
          label: <T _str="Master" />,
        },
        role && role
      )}
      onView={() => {
        history.push(routingMap.app['users/:userId'](id)._)
      }}
      loading={loading}
    />
  )
}

export default UserPopup
