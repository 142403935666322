import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

import { CompanyEmailAccountMessageEventType } from 'src/gen-types'
import { createOptionsFromLabelMap } from 'src/utils/create-options'

export const customerInboxEventTypeLabelMap: Record<
  CompanyEmailAccountMessageEventType,
  ReactNode
> = {
  STATUS_CHANGED: <T _str="Change Status" />,
  ASSIGNEE_CHANGED: <T _str="Change Assignee" />,
  ASSIGNED_TEAM_CHANGED: <T _str="Change Assigned Team" />,
  ASSOCIATED_TICKET_CREATED_AND_LINKED: (
    <T _str="Create and link associated ticket" />
  ),
  ASSOCIATED_TICKET_LINKED_EXISTING: (
    <T _str="Link existing associated ticket" />
  ),
  ASSOCIATED_TICKET_UNLINKED: <T _str="Unlink associated ticket" />,
  MESSAGE_RECEIVED: <T _str="Message received" />,
  MESSAGE_SENT: <T _str="Message sent" />,
}

export const customInboxEventTypeOptions = createOptionsFromLabelMap(
  customerInboxEventTypeLabelMap
)

export const customerInboxMessageEvents: CompanyEmailAccountMessageEventType[] =
  [
    CompanyEmailAccountMessageEventType.MESSAGE_RECEIVED,
    CompanyEmailAccountMessageEventType.MESSAGE_SENT,
  ]
