import { gql } from '@apollo/client'

export const FrgDepotForLink = gql`
  fragment FrgDepotForLink on DepotType {
    id
    name
    logo
    color
  }
`

export const FrgDepot = gql`
  ${FrgDepotForLink}
  fragment FrgDepot on DepotType {
    ...FrgDepotForLink
    company {
      id
      name
    }
    location {
      id
      latitude
      longitude
      placeId
      name
    }
    openingTime
    closingTime
    contactPerson {
      id
      email
      name
      phone
    }
    depotType
    wasteFractions {
      edges {
        node {
          id
          name
          wasteCategory
          wasteSubstance
          weightFactor
        }
      }
    }
  }
`
