import { gql, useMutation } from '@apollo/client'

import {
  BulkUpdateTickets,
  BulkUpdateTicketsVariables,
} from './__generated__/types'
import { FrgUserForLink } from './user'
import { FrgWasteFraction } from './waste-fraction'

export const FrgTicketForTable = gql`
  ${FrgWasteFraction}
  ${FrgUserForLink}
  fragment FrgTicketForTable on TicketDef {
    id
    description
    createdAt
    ticketType {
      id
      name
    }
    location {
      id
      name
    }
    status
    priority
    property {
      id
      type {
        id
        name
      }
      externalPropertyNumber
      location {
        id
        name
      }
    }
    agreement {
      id
      startDate
      endDate
      property {
        id
      }
    }
    container {
      id
      containerId
      location {
        id
        name
      }
      containerType {
        id
        name
      }
      wasteFraction {
        ...FrgWasteFraction
      }
    }
    assignee {
      ...FrgUserForLink
    }
    statusOpenAt
    statusRejectedAt
    statusCompletedAt
    statusPendingAt
    statusOnHoldAt
    statusInProgressAt
    preferredDate
  }
`

export const MUTATION_BULK_SET_TICKET_ON_ROUTES = gql`
  mutation MutationBulkSetTicketRoutes($newRoutes: [BulkSetTicketRouteInput!]) {
    bulkSetTicketRoutes(newRoutes: $newRoutes) {
      tickets {
        id
        pickupOrder {
          id
        }
      }
    }
  }
`

export const FrgTicketMessageForHistory = gql`
  fragment FrgTicketMessageForHistory on TicketMessageDef {
    id
    createdAt
    isOutbound
    isInbound
    via
    messageStatus
    body
    createdByAction
    sender {
      ...FrgUserForLink
      email
    }
    receiverPropertyContact {
      id
      phoneNumber
      email
      taxId
      name
      isOwner
      isAdministrator
      isTenant
      isCoOwner
      isPayer
      isContact
    }
    receiverEmail
    receiverPropertyUser {
      id
      name
      email
      phoneNumber
    }
    attachments {
      id
      file
      mimeType
    }
    companyEmailAccount {
      id
      email
    }
    senderPropertyUser {
      id
      name
      email
    }
    senderPropertyContact {
      id
      name
      email
    }
  }
`

const FrgTicketForHistory = gql`
  ${FrgUserForLink}
  ${FrgTicketMessageForHistory}
  fragment FrgTicketForHistory on TicketDef {
    ticketType {
      id
      name
    }
    container {
      id
      location {
        id
        name
      }
    }
    property {
      id
      location {
        id
        name
      }
    }
    eventLog(
      fromDate: $fromDate
      first: $first
      toDate: $toDate
      offset: $offset
      ticketEventTypes: $ticketEventTypes
      ticketMessageVia: $ticketMessageVia
      addTicketComments: $addTicketComments
      addTicketEvents: $addTicketEvents
      addTicketAttachmentEvents: $addTicketAttachment
      ticketAttachmentEventTypes: $ticketAttachmentsEvents
      addTicketMessageAttachmentEvents: false
      addTicketCommentAttachmentEvents: false
      search: $search
    ) {
      totalCount
      edges {
        ... on TicketEventDef {
          id
          eventType
          createdAt
          modifiedData
          createdByAction
          createdBy {
            ...FrgUserForLink
          }
          fromStatus
          toStatus
          fromPriority
          toPriority
          fromAssignee {
            ...FrgUserForLink
          }
          fromAssignedTeam {
            id
            name
          }
          toAssignedTeam {
            id
            name
          }
          toAssignee {
            ...FrgUserForLink
          }
          fromAssociatedInboxMessages {
            edges {
              node {
                id
              }
            }
          }
          toAssociatedInboxMessages {
            edges {
              node {
                id
              }
            }
          }
          modifiedData
          barcodeFileUrl
        }
        ... on TicketCommentDef {
          id
          createdAt
          author {
            ...FrgUserForLink
          }
          authorName
          body
          attachments {
            id
            file
            mimeType
          }
        }
        ... on TicketMessageDef {
          ...FrgTicketMessageForHistory
        }
        ... on TicketAttachmentEventDef {
          id
          createdAt
          ticketAttachmentEventType: eventType
          user {
            ...FrgUserForLink
          }
        }
        ... on TicketMessageAttachmentEventDef {
          id
          createdAt
          ticketMessageAttachmentEventType: eventType
          user {
            ...FrgUserForLink
          }
        }
        ... on TicketCommentAttachmentEventDef {
          id
          createdAt
          ticketCommentAttachmentEventType: eventType
          user {
            ...FrgUserForLink
          }
        }
      }
    }
  }
`

export const GET_TICKET_HISTORY = gql`
  ${FrgTicketForHistory}
  query GetTicketHistory(
    $id: ID!
    $fromDate: Date
    $toDate: Date
    $first: Int
    $offset: Int
    $ticketEventTypes: TicketEventTypeValuesList
    $ticketMessageVia: [String]
    $addTicketComments: Boolean
    $addTicketEvents: Boolean
    $addTicketAttachment: Boolean
    $ticketAttachmentsEvents: [GenericAddRemoveEventTypesGraphene]
    $search: String
  ) {
    ticket(id: $id) {
      ...FrgTicketForHistory
    }
  }
`

export const ON_TICKET_CHANGED = gql`
  ${FrgTicketForHistory}
  subscription OnTicketChanged(
    $id: ID!
    $fromDate: Date
    $toDate: Date
    $first: Int
    $offset: Int
    $ticketEventTypes: TicketEventTypeValuesList
    $ticketMessageVia: [String]
    $addTicketComments: Boolean
    $addTicketEvents: Boolean
    $addTicketAttachment: Boolean
    $ticketAttachmentsEvents: [GenericAddRemoveEventTypesGraphene]
    $search: String
  ) {
    onTicketChanged(ticketId: $id) {
      ticket {
        ...FrgTicketForHistory
      }
    }
  }
`

export const BULK_UPDATE_TICKETS = gql`
  mutation BulkUpdateTickets(
    $data: TicketBulkUpdateCreateData
    $input: [TicketBulkUpdateCreateInput]
  ) {
    updateOrCreateBulkTicket(data: $data, input: $input) {
      ticketList {
        id
      }
    }
  }
`

export const useBulkUpdateTickets = () => {
  return useMutation<BulkUpdateTickets, BulkUpdateTicketsVariables>(
    BULK_UPDATE_TICKETS
  )
}
