import { gql } from '@apollo/client'

export const FrgVehicleAbsence = gql`
  fragment FrgVehicleAbsence on VehicleAbsenceType {
    id
    project {
      id
      name
    }
    vehicle {
      id
      name
    }
    replacementVehicle {
      id
      name
    }
    start
    end
    absenceType
  }
`

export const GET_VEHICLE_ABSENCE = gql`
  query GetVehicleAbsence($id: ID!) {
    vehicleAbsence(id: $id) {
      id
      project {
        id
        name
      }
      vehicle {
        id
        name
      }
      replacementVehicle {
        id
        name
      }
      start
      end
      absenceType
    }
  }
`
export const GET_ALL_VEHICLE_ABSENCES = gql`
  ${FrgVehicleAbsence}
  query GetAllVehicleAbsences(
    $activeProjects: String
    $offset: Int
    $first: Int
    $orderBy: String
    $order: String
  ) {
    allVehicleAbsences(
      activeProjects: $activeProjects
      offset: $offset
      first: $first
      orderBy: $orderBy
      order: $order
    ) {
      totalCount
      edges {
        node {
          ...FrgVehicleAbsence
        }
      }
    }
  }
`

export const CREATE_OR_UPDATE_VEHICLE_ABSENCE = gql`
  ${FrgVehicleAbsence}
  mutation CreateOrUpdateVehicleAbsence(
    $selfId: ID
    $projectId: ID!
    $vehicleId: String!
    $replacementVehicleId: String!
    $start: Date!
    $end: Date!
    $absenceType: String!
  ) {
    updateOrCreateVehicleAbsence(
      selfId: $selfId
      projectId: $projectId
      vehicleId: $vehicleId
      replacementVehicleId: $replacementVehicleId
      start: $start
      end: $end
      absenceType: $absenceType
    ) {
      vehicleAbsence {
        ...FrgVehicleAbsence
      }
    }
  }
`
export const DELETE_VEHICLE_ABSENCE = gql`
  mutation DeleteVehicleAbsence($id: ID) {
    deleteVehicleAbsence(id: $id) {
      status
    }
  }
`
