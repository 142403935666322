import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import dayjs from 'dayjs'

import {
  GetUnReadNotifications,
  GetUnReadNotificationsVariables,
  MarkAllUserNotificationsRead,
  MarkAllUserNotificationsSeen,
  MarkUserNotificationRead,
  MarkUserNotificationReadVariables,
  SubscriptionUserNotifications,
} from './__generated__/types'
import { FrgUserForLink } from './user'

export const FrgTicketInfo = gql`
  fragment FrgTicketInfo on TicketDef {
    id
    ticketType {
      id
      name
    }
    container {
      id
      containerId
    }
    location {
      name
    }
    container {
      containerId
    }
    ticketStatus: status
  }
`

const FrgCompanyEmailAccountInfo = gql`
  fragment FrgCompanyEmailAccountInfo on CompanyEmailAccountMessageDef {
    id
    isInbound
    createdAt
    companyEmailAccountStatus: status
    fromEmail
    toEmail
    body
    subject
    companyEmailAccount {
      id
      email
    }
    threadStarter {
      id
    }
    attachments {
      id
    }
  }
`

export const FrgEventInfo = gql`
  fragment FrgEventInfo on UserNotificationType {
    id
    createdAt
    dateRead
    user {
      id
    }
    event {
      ... on TicketEventDef {
        id
        ticket {
          ...FrgTicketInfo
        }
        ticketEventType: eventType
        createdAt
        createdBy {
          ...FrgUserForLink
        }
        ticketFromStatus: fromStatus
        ticketToStatus: toStatus
        ticketFromAssignee: fromAssignee {
          ...FrgUserForLink
        }
        ticketToAssignee: toAssignee {
          ...FrgUserForLink
        }
        ticketFromAssignedTeam: fromAssignedTeam {
          id
          name
        }
        ticketToAssignedTeam: toAssignedTeam {
          id
          name
        }
        fromPriority
        toPriority
        barcodeFileUrl
        company {
          id
        }
        createdByAction
        eventType
        __typename
      }
      ... on TicketMessageDef {
        id
        createdAt
        sender {
          ...FrgUserForLink
        }
        createdBy {
          ...FrgUserForLink
        }
        receiverPropertyUser {
          id
        }
        body
        attachments {
          id
        }
        __typename
        ticket {
          ...FrgTicketInfo
        }
      }
      ... on TicketCommentDef {
        id
        createdAt
        author {
          ...FrgUserForLink
        }
        body
        attachments {
          id
        }
        ticket {
          ...FrgTicketInfo
        }
      }
      ... on CompanyEmailAccountMessageEventDef {
        id
        createdAt
        createdBy {
          ...FrgUserForLink
        }
        companyEmailAccountEventType: eventType
        companyEmailAccountMessage {
          ...FrgCompanyEmailAccountInfo
        }
        companyEmailAccountFromStatus: fromStatus
        companyEmailAccountToStatus: toStatus
        companyEmailAccountFromAssignee: fromAssignee {
          ...FrgUserForLink
        }
        companyEmailAccountToAssignee: toAssignee {
          ...FrgUserForLink
        }
        companyEmailAccountFromAssignedTeam: fromAssignedTeam {
          id
          name
        }
        companyEmailAccountToAssignedTeam: toAssignedTeam {
          id
          name
        }
      }
    }
  }
`

export const GET_ALL_GROUPED_USER_NOTIFICATIONS = gql`
  ${FrgTicketInfo}
  ${FrgUserForLink}
  ${FrgEventInfo}
  ${FrgCompanyEmailAccountInfo}
  query GetAllGroupedUserNotifications(
    $first: Int
    $offset: Int
    $unread: Boolean
    $createdAtFrom: DateTime
    $createdAtTo: DateTime
    $followingOnly: Boolean
    $directOnly: Boolean
  ) {
    allUserEventsNotifications(
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      first: $first
      unread: $unread
      offset: $offset
      followingOnly: $followingOnly
      directOnly: $directOnly
    ) {
      edges {
        ... on TicketDef {
          ...FrgTicketInfo
          createdAt
          notificationsLog {
            edges {
              ...FrgEventInfo
            }
          }
        }
        ... on CompanyEmailAccountMessageDef {
          ...FrgCompanyEmailAccountInfo
          notificationsLog {
            edges {
              ...FrgEventInfo
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_USER_NOTIFICATIONS = gql`
  ${FrgTicketInfo}
  ${FrgUserForLink}
  ${FrgEventInfo}
  ${FrgCompanyEmailAccountInfo}
  query GetAllUserNotifications(
    $first: Int
    $offset: Int
    $orderBy: String
    $order: String
    $unread: Boolean
    $createdAtFrom: DateTime
    $followingOnly: Boolean
    $directOnly: Boolean
  ) {
    allUserNotifications(
      first: $first
      orderBy: $orderBy
      order: $order
      unread: $unread
      offset: $offset
      createdAtFrom: $createdAtFrom
      followingOnly: $followingOnly
      directOnly: $directOnly
    ) {
      totalCount
      edges {
        node {
          ...FrgEventInfo
        }
      }
    }
  }
`

export const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
  query GetUnReadNotifications(
    $createdAtFrom: DateTime
    $directOnly: Boolean
    $followingOnly: Boolean
  ) {
    allUserNotifications(
      unseen: true
      first: 0
      createdAtFrom: $createdAtFrom
      directOnly: $directOnly
      followingOnly: $followingOnly
    ) {
      totalCount
    }
  }
`

export const MARK_ALL_USER_NOTIFICATIONS_READ = gql`
  mutation MarkAllUserNotificationsRead {
    markAllUserNotificationsRead {
      status
    }
  }
`

export const MARK_ALL_USER_NOTIFICATIONS_SEEN = gql`
  mutation MarkAllUserNotificationsSeen(
    $directOnly: Boolean
    $followingOnly: Boolean
  ) {
    markAllUserNotificationsSeen(
      directOnly: $directOnly
      followingOnly: $followingOnly
    ) {
      status
    }
  }
`

export const MARK_USER_NOTIFICATION_READ = gql`
  mutation MarkUserNotificationRead($id: ID!) {
    markUserNotificationRead(id: $id) {
      userNotification {
        dateRead
      }
    }
  }
`

export const SUBSCRIBE_NOTIFICATIONS = gql`
  subscription SubscriptionUserNotifications {
    onUserNotification {
      userNotification {
        id
        createdAt
        dateRead
        user {
          id
          username
        }
      }
    }
  }
`

export const useUnReadNotifications = ({
  variables,
}: {
  variables?: GetUnReadNotificationsVariables
} = {}) => {
  const { data, ...rest } = useQuery<
    GetUnReadNotifications,
    GetUnReadNotificationsVariables
  >(GET_UNREAD_NOTIFICATIONS_COUNT, {
    variables: {
      createdAtFrom: dayjs().subtract(29, 'days').startOf('day').toISOString(),
      ...variables,
    },
  })
  return { data: data?.allUserNotifications?.totalCount || 0, ...rest }
}

export const useMarkAllNotifications = () => {
  const [markAllRead] = useMutation<MarkAllUserNotificationsRead>(
    MARK_ALL_USER_NOTIFICATIONS_READ,
    { refetchQueries: [GET_UNREAD_NOTIFICATIONS_COUNT] }
  )

  const [markAllSeen] = useMutation<MarkAllUserNotificationsSeen>(
    MARK_ALL_USER_NOTIFICATIONS_SEEN,
    { refetchQueries: [GET_UNREAD_NOTIFICATIONS_COUNT] }
  )

  return [markAllRead, markAllSeen] as const
}

export const useMarkNotification = () => {
  return useMutation<
    MarkUserNotificationRead,
    MarkUserNotificationReadVariables
  >(MARK_USER_NOTIFICATION_READ, {
    refetchQueries: [GET_UNREAD_NOTIFICATIONS_COUNT],
  })
}

export const useSubscribeToNotifications = (onPing?: () => void) => {
  return useSubscription<SubscriptionUserNotifications>(
    SUBSCRIBE_NOTIFICATIONS,
    {
      onData: ({ client }) => {
        onPing?.()
        client.refetchQueries({
          include: [GET_UNREAD_NOTIFICATIONS_COUNT],
        })
      },
    }
  )
}
