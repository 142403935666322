import React from 'react'
import { ApolloProvider } from '@apollo/client'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'animate.css'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import duration from 'dayjs/plugin/duration'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import 'mapbox-gl/dist/mapbox-gl.css'
import { createRoot } from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import 'typeface-roboto'

import * as colors from 'src/styles/color-theme'

import App from './App'
import client from './components/graphql/apollo'
import './fonts.css'
import * as serviceWorker from './serviceWorker'
import { ErrorBoundary } from './setupSentry'
import './setupTransifex'

dayjs.extend(duration)
dayjs.extend(localeData)
dayjs.extend(calendar)
dayjs.extend(isoWeek)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.mapstyles = process.env.REACT_APP_MAPBOX_STYLES

const root = createRoot(document.getElementById('main')!)
root.render(
  <ApolloProvider client={client}>
    <ErrorBoundary>
      <ThemeProvider theme={colors}>
        <App />
      </ThemeProvider>
    </ErrorBoundary>
  </ApolloProvider>
)

serviceWorker.unregister()
