import { gql } from '@apollo/client'

import { FrgUserForLink } from 'src/api'

export const GET = gql`
  ${FrgUserForLink}
  query GetUserInfoPopover($id: ID!) {
    user(id: $id) {
      ...FrgUserForLink
      phoneNumber
      email
      companyRole {
        id
        name
      }
      isMaster
    }
  }
`
