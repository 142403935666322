import { useEffect } from 'react'
import { create } from 'zustand'

import { useMe } from './useMe'

type TDefaultConfigStore = {
  defaultLocation: {
    latitude: number
    longitude: number
    name: string
    placeId: string
  }
}

const initialLocation: TDefaultConfigStore['defaultLocation'] = {
  latitude: 0,
  longitude: 0,
  name: '',
  placeId: '',
}

export const useDefaultConfigStore = create<TDefaultConfigStore>(() => ({
  defaultLocation: initialLocation,
}))

export const DefaultConfigInit = ({ children }: { children: JSX.Element }) => {
  const { company } = useMe()

  useEffect(() => {
    useDefaultConfigStore.setState({
      defaultLocation: {
        latitude:
          company?.dashboardLocation?.latitude || initialLocation.latitude,
        longitude:
          company?.dashboardLocation?.longitude || initialLocation.longitude,
        name: company?.dashboardLocation?.name || initialLocation.name,
        placeId: company?.dashboardLocation?.placeId || initialLocation.placeId,
      },
    })
  }, [company])

  return children
}
