import {
  gql,
  useMutation,
  MutationHookOptions,
  useApolloClient,
} from '@apollo/client'

import { FrgCompanySettingsPinColors } from 'src/hooks/useCompanyPinColors'
import { setUser } from 'src/reactive-variables'

import { FrgUserForLink } from './user'
import { useRoutingHistory } from '../reducers/routing/child-router-factory'

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    refuseToken {
      token
    }
  }
`

export const useLogoutMutation = (options?: MutationHookOptions) =>
  useMutation(MUTATION_LOGOUT, options)

export const MUTATION_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    tokenAuth(password: $password, username: $username) {
      token
    }
  }
`

export const FrgActiveProject = gql`
  fragment FrgActiveProject on ProjectType {
    id
    name
    userAccessLevel
    logo
    company {
      id
      name
      dashboardLocation {
        id
        latitude
        longitude
        placeId
        name
      }
    }
    containerTypes {
      edges {
        node {
          id
          name
          length
          width
          height
          diameter
          isCylindrical
          volume
        }
      }
    }
    measurementsettingsSet {
      edges {
        node {
          id
        }
      }
    }
    wasteFractions {
      edges {
        node {
          id
          name
          wasteCategory
          wasteSubstance
          weightFactor
        }
      }
    }
  }
`

export const GET_ME = gql`
  ${FrgActiveProject}
  ${FrgUserForLink}
  ${FrgCompanySettingsPinColors}
  query GetMeByProjectIds($projectList: [ID]) {
    me {
      ...FrgUserForLink
      isStaff
      isAdmin
      isSuperuser
      isDemo
      isReseller
      isMaster
      isBeta
      userId
      email
      intercomUserHash
      createdAt
      phoneNumber
      mixpanelIdentity
      datetimeFormatLocale
      activeProjectsIds
      daysBeforeDemoExpires
      language
      ownProjectsIds
      ownProjects {
        edges {
          node {
            id
            name
          }
        }
      }
      jobtitle {
        id
        title
      }
      activeProjects(projectsIds: $projectList) {
        edges {
          node {
            ...FrgActiveProject
          }
        }
      }
      company {
        id
        logo
        name
        companySettings {
          id
          mapCountry
          ...FrgCompanySettingsPinColors
        }
        settings {
          clockFormat12
        }
        dashboardLocation {
          id
          latitude
          longitude
          placeId
          name
        }
      }
      userLayoutConfig {
        id
        driverOrder
        driverOrderBy
        driverPageSize
        driverIconsSize
        driverDisabledColumns
        locationsOrder
        locationsOrderBy
        locationsPageSize
        locationsIconsSize
        locationsDisabledColumns
        vehicleOrder
        vehicleOrderBy
        vehiclePageSize
        vehicleIconsSize
        vehicleDisabledColumns
        vehicleTypesOrder
        vehicleTypesOrderBy
        vehicleTypesPageSize
        vehicleTypesDisabledColumns
        routeOrder
        routeOrderBy
        routePageSize
        routeDisabledColumns
        inquiryOrder
        inquiryOrderBy
        inquiryPageSize
        inquiryDisabledColumns
        containersOrder
        containersOrderBy
        containersPageSize
        containersDisabledColumns
        collectionContainersOrder
        collectionContainersOrderBy
        collectionContainersPageSize
        collectionContainersDisabledColumns
        pickupOrdersOrder
        pickupOrdersOrderBy
        pickupOrdersPageSize
        pickupOrdersDisabledColumns
        notificationsOrder
        notificationsOrderBy
        notificationsPageSize
        notificationsDisabledColumns
        analyticsCalculationType
        analyticsPreviousPeriod
        analyticsCustomPeriodFrom
        analyticsCustomPeriodTo
        analyticsCustomPeriodComparisonFrom
        analyticsCustomPeriodComparisonTo
        analyticsHistoricalPeriod
      }
    }
  }
`

export const GET_ACTIVE_PROJECTS_DETAILS = gql`
  ${FrgActiveProject}
  query GetActiveProjectsDetails($ids: [ID]) {
    allProjects(ids: $ids) {
      edges {
        node {
          ...FrgActiveProject
        }
      }
    }
  }
`

export const MUTATION_VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload {
        username
        exp
        origIat
      }
      user {
        username
        lastName
        firstName
      }
    }
  }
`

type LoginFnArgs = {
  username: string
  password: string
  rememberMe: boolean
  onSuccess?: (args: { routed: boolean }) => void
}

export const useLogin = () => {
  const [loginMutationQuery] = useMutation(MUTATION_LOGIN)
  const client = useApolloClient()
  const [history, { routingMap }] = useRoutingHistory()

  const login = async ({
    username,
    password,
    rememberMe,
    onSuccess,
  }: LoginFnArgs) => {
    await client.resetStore()
    const {
      data: { tokenAuth },
    } = await loginMutationQuery({
      variables: {
        username,
        password,
        rememberMe,
      },
    })
    let routed = false

    window.localStorage.setItem('reactQLJWT', tokenAuth.token)
    window.localStorage.setItem('rememberMe', String(rememberMe))
    await client.query({ query: GET_ME }).then((response) => {
      if (response.data && response.data.me) {
        setUser(response.data.me)
        if (!response.data.me.company) {
          history.push(routingMap.company.create._)
          routed = true
        }
      }
    })
    onSuccess?.({ routed })
  }

  return { login }
}
