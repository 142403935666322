import dayjs from 'dayjs'
import { times } from 'lodash'

/** List of the months in the current locale. */
export const months = dayjs.months()

export const monthsLabelMap = months.map((month, i) => ({
  label: month,
  value: i,
}))

export const monthsDuration = times(months.length, (i) =>
  // 2024 is set as a leap year to avoid issues with February
  dayjs().year(2024).month(i).daysInMonth()
)
