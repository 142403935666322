import React, { useState } from 'react'
import { T } from '@transifex/react'
import { Row } from 'antd'
import {
  TwitterPicker as RCTwitterPicker,
  ChromePicker as RCChromePicker,
  ChromePickerProps as RSChromePickerProps,
  ColorChangeHandler,
} from 'react-color'

import * as theme from 'src/styles/color-theme'

import ChromePresets from './ChromePresets'
import presetColors from './preset'
import { StyledCircle, StyledLabel, StyledSelect } from './ui'

export { StyledCircle }

export type ColorPickerProps = {
  value?: string
  onChange?: (color: string) => void
  /** Custom swatch colors */
  colors?: string[]
}

const defaultColors = [
  theme.gray1000,
  theme.gray100,
  '#FF6900',
  '#FCB900',
  '#00D084',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
]

const TwitterColorPicker = ({ value, onChange, colors }: ColorPickerProps) => {
  return (
    <RCTwitterPicker
      colors={colors ?? defaultColors}
      color={value}
      onChange={(c) => onChange?.(c.hex)}
      width="192px"
      styles={{
        default: {
          body: {
            width: 192,
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 2,
            paddingBottom: 2,
          },
          swatch: {
            width: 20,
            height: 20,
            boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 4px',
          },
          input: {
            height: 20,
            width: 58,
          },
          label: {
            height: 20,
          },
          hash: {
            height: 22,
          },
        },
      }}
      triangle="hide"
    />
  )
}

const chromePickerStyles: RSChromePickerProps['styles'] = {
  default: {
    picker: {
      boxShadow: 'none',
    },
    saturation: {
      borderRadius: 2,
    },
  },
}
type TChromeColorPickerProps = {
  value?: string
  onChange?: (color: string) => void
  disabled?: boolean
  withPreset?: boolean
}

const ChromeColorPicker = ({
  onChange,
  disabled = false,
  value = '#000000',
  withPreset,
}: TChromeColorPickerProps) => {
  const [open, setOpen] = useState(false)

  const handleChange: ColorChangeHandler = (color) => {
    onChange?.(color.hex.toUpperCase())
  }

  const handlePreset = (color: string) => {
    onChange?.(color)
    setOpen(false)
  }

  return (
    <div>
      <StyledLabel onClick={() => setOpen(!open)}>
        <StyledCircle $color={value} />
        {presetColors.find((preset) => preset.value === value)?.label || (
          <T _str="Custom ({color})" color={value} />
        )}
      </StyledLabel>
      <StyledSelect
        open={open}
        value={value}
        disabled={disabled}
        popupMatchSelectWidth={false}
        dropdownStyle={{ minWidth: 200, overflow: 'hidden' }}
        dropdownRender={() => (
          <Row>
            {withPreset && <ChromePresets onChange={handlePreset} />}
            <RCChromePicker
              disableAlpha
              color={value}
              onChangeComplete={handleChange}
              styles={chromePickerStyles}
            />
          </Row>
        )}
        onDropdownVisibleChange={setOpen}
      />
    </div>
  )
}

export { TwitterColorPicker, ChromeColorPicker }
