import React from 'react'
import { useQuery } from '@apollo/client'
import { T } from '@transifex/react'
import { Col, Row, Skeleton } from 'antd'

import { customerInboxStatusChoicesSelectOptions } from 'src/api/consts/CustomerInboxMessageStatusChoices'
import { Typography } from 'src/storybook/components'
import { divSecondary } from 'src/styles/color-theme'
import extractIdFromEncodedString from 'src/utils/extractIdFromEncodedString'

import {
  GetCustomerInboxPopupInfo,
  GetCustomerInboxPopupInfoVariables,
} from './__generated__/types'
import { GET_CUSTOMER_INBOX_POPUP_INFO } from './api'
import { PopupContentProps } from '..'

export default function CustomerInboxPopup({ id }: PopupContentProps) {
  const { data: { companyEmailAccountMessage: data } = {}, loading } = useQuery<
    GetCustomerInboxPopupInfo,
    GetCustomerInboxPopupInfoVariables
  >(GET_CUSTOMER_INBOX_POPUP_INFO, { variables: { id } })

  const ticketData = [
    {
      label: <T _str="Sender email" />,
      value: <Typography variant="body-14">{data?.fromEmail}</Typography>,
    },
    {
      label: <T _str="Received email" />,
      value: (
        <Typography variant="body-14">
          {data?.companyEmailAccount?.email}
        </Typography>
      ),
    },
  ]

  if (loading && !data)
    return (
      <div style={{ width: 360, padding: '8px 12px' }}>
        <Skeleton active paragraph={{ rows: 3 }} />
      </div>
    )

  return (
    <div style={{ width: 360 }}>
      <div
        style={{
          padding: '8px 12px',
          borderBottom: `1px solid ${divSecondary}`,
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <Typography variant="subHeader-16" weight="semi-bold">
              {extractIdFromEncodedString(data?.id)}
            </Typography>
          </Col>
          <Col>
            {data?.status &&
              customerInboxStatusChoicesSelectOptions.find(
                (option) => option.value === data.status
              )?.label}
          </Col>
        </Row>
      </div>
      <div style={{ padding: '8px 12px' }}>
        <Row gutter={[8, 8]}>
          {ticketData.map(({ label, value }, index) => (
            <Col span={24} key={index}>
              <Row align="top" wrap={false}>
                <Col flex="0 0 100px">
                  <Typography variant="body-14" type="secondary">
                    {label}
                  </Typography>
                </Col>
                <Col flex="auto">{value}</Col>
              </Row>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}
