import { gql } from '@apollo/client'

export const FrgProjectForLink = gql`
  fragment FrgProjectForLink on ProjectType {
    id
    name
    logo
    color
  }
`

export const GET_PROJECT = gql`
  ${FrgProjectForLink}
  query GetProject($id: ID!) {
    project(id: $id) {
      ...FrgProjectForLink
      description
      userAccessLevel
      company {
        id
      }
    }
  }
`

export const UPDATE_OR_CREATE_PROJECT = gql`
  ${FrgProjectForLink}
  mutation UpdateOrCreateProject($input: UpdateOrCreateProjectInput!) {
    updateOrCreateProject(input: $input) {
      project {
        ...FrgProjectForLink
        description
        userAccessLevel
        company {
          id
          name
        }
        wasteFractions {
          edges {
            node {
              id
              name
              wasteCategory
              wasteSubstance
              weightFactor
            }
          }
        }
        containerTypes {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`
