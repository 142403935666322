import React from 'react'
import { T } from '@transifex/react'

import { pathJoin } from 'src/reducers/routing/utils'

import { createAssetManagementRoutesMap } from './asset-management'
import { createSubscriptionRoutesMap } from './billing'
import { createCRMRoutesMap } from './crm'
import { createCrmPortalViewRoutesMap } from './crm-portal'
import { createFleetManagementRoutesMap } from './fleet-management'
import { createNavigationAppRoutesMap } from './navigation-app'
import { createOperationManagementRoutesMap } from './operation-management'
import { createTicketsRoutesMap } from './tickets'
import { createUserMangementRoutesMap } from './user-management'

export const createCompanyRoutesMap = (prefix: string) => ({
  general: pathJoin(prefix)('general').chainWithTitle({
    title: <T _str="General" />,
    breadCrumbTitle: <T _str="General" />,
  }),
  settings: pathJoin(prefix)('settings').chainWithTitle({
    title: <T _str="Settings" />,
    breadCrumbTitle: <T _str="Settings" />,
  }),
  'crm-portal': pathJoin(prefix)('crm-portal').chainMapAuto(
    createCrmPortalViewRoutesMap,
    {
      title: <T _str="Portal Settings" />,
      breadCrumbTitle: <T _str="Portal Settings" />,
    }
  ),
  'email-accounts': pathJoin(prefix)('email-accounts').chainWithTitle({
    title: <T _str="Email Accounts" />,
    breadCrumbTitle: <T _str="Email Accounts" />,
  }),
  'criipto-application': pathJoin(prefix)('criipto-application').chainWithTitle(
    {
      title: <T _str="Criipto Application" />,
      breadCrumbTitle: <T _str="Criipto Application" />,
    }
  ),
  'digital-post': pathJoin(prefix)('digital-post').chainWithTitle({
    title: <T _str="Digital Post" />,
    breadCrumbTitle: <T _str="Digital Post" />,
  }),
  crm: pathJoin(prefix)('crm').chainMapAuto(createCRMRoutesMap, {
    title: <T _str="Customer Management" />,
    breadCrumbTitle: <T _str="Customer Management" />,
  }),
  'fleet-management': pathJoin(prefix)('fleet-management').chainMapAuto(
    createFleetManagementRoutesMap,
    {
      title: <T _str="Fleet Management" />,
      breadCrumbTitle: <T _str="Fleet Management" />,
    }
  ),
  billing: pathJoin(prefix)('billing').chainMapAuto(
    createSubscriptionRoutesMap,
    {
      title: <T _str="Billing" />,
      breadCrumbTitle: <T _str="Billing" />,
    }
  ),
  tickets: pathJoin(prefix)('tickets').chainMapAuto(createTicketsRoutesMap, {
    title: <T _str="Tickets" />,
    breadCrumbTitle: <T _str="Tickets" />,
  }),
  'user-management': pathJoin(prefix)('user-management').chainMapAuto(
    createUserMangementRoutesMap,
    {
      title: <T _str="User Management" />,
      breadCrumbTitle: <T _str="User Management" />,
    }
  ),
  'operation-management': pathJoin(prefix)('operation-management').chainMapAuto(
    createOperationManagementRoutesMap,
    {
      title: <T _str="Operation Management" />,
      breadCrumbTitle: <T _str="Operation Management" />,
    }
  ),
  'alert-rules': pathJoin(prefix)('alert-rules').chainMapAuto(
    (prefix2) => ({
      ':id': (id = ':id') => pathJoin(prefix2)(id).chain,
    }),
    {
      title: <T _str="Alert Rules" />,
      breadCrumbTitle: <T _str="Alert Rules" />,
    }
  ),
  documents: pathJoin(prefix)('documents').chainWithTitle({
    title: <T _str="Documents" />,
    breadCrumbTitle: <T _str="Documents" />,
  }),
  'asset-management': pathJoin(prefix)('asset-management').chainMapAuto(
    createAssetManagementRoutesMap,
    {
      title: <T _str="Asset Management" />,
      breadCrumbTitle: <T _str="Asset Management" />,
    }
  ),
  history: pathJoin(prefix)('history').chainWithTitle({
    title: <T _str="History" />,
    breadCrumbTitle: <T _str="History" />,
  }),
  'navigation-app': pathJoin(prefix)('navigation-app').chainMapAuto(
    createNavigationAppRoutesMap,
    {
      title: <T _str="Navigation App" />,
      breadCrumbTitle: <T _str="Navigation App" />,
    }
  ),
})
