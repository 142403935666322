import { gql } from '@apollo/client'

import { FrgUserForLink } from './user'
import { FrgVehicleForLink } from './vehicle'

export const FrgDriver = gql`
  ${FrgUserForLink}
  ${FrgVehicleForLink}
  fragment FrgDriver on DriverType {
    id
    driverType
    user {
      ...FrgUserForLink
    }
    status
    collectedBins
    vehicles {
      edges {
        node {
          id
          name
          vehicleType {
            id
            Type
          }
          project {
            id
          }
        }
      }
    }
    primaryVehicle {
      ...FrgVehicleForLink
    }
  }
`

export const GET_ALL_DRIVERS = gql`
  ${FrgDriver}
  query GetAllDrivers(
    $name: String
    $pageSize: Int
    $offset: Int
    $status: [String]
    $order: String
    $orderBy: String
    $havingVehicle: Boolean
    $activeProjects: String
    $search: String
    $vehicles: [ID]
    $primaryVehicle: ID
  ) {
    allDrivers(
      name: $name
      first: $pageSize
      offset: $offset
      status_In: $status
      order: $order
      orderBy: $orderBy
      havingVehicle: $havingVehicle
      activeProjects: $activeProjects
      vehicles: $vehicles
      primaryVehicle: $primaryVehicle
      search: $search
    ) {
      totalCount
      edges {
        node {
          ...FrgDriver
        }
      }
    }
  }
`
