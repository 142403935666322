import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react'

import { useDeepCompareEffect, useMeasure } from 'src/hooks'

type CollapseDirection = 'start' | 'end'

export interface OverflowListProps<T> {
  items: T[]
  itemRenderer: (item: T, index: number) => ReactNode
  overflowRenderer: (items: T[]) => ReactNode
  minVisibleItems?: number
  collapseFrom?: CollapseDirection
  className?: string
  alwaysRenderOverflow?: boolean
}

interface OverflowListState<T> {
  visible: T[]
  overflow: T[]
  renderCount: number
}

export default function OverflowList<T>({
  items,
  collapseFrom = 'end',
  minVisibleItems = 0,
  className = '',
  alwaysRenderOverflow = false,
  overflowRenderer,
  itemRenderer,
}: OverflowListProps<T>) {
  const [state, setState] = useState<OverflowListState<T>>({
    visible: items,
    overflow: [],
    renderCount: Math.random(),
  })
  const wrapper = useRef<HTMLDivElement | null>(null)
  const [measureRef, { width }] = useMeasure<HTMLDivElement>()

  const maybeOverflow =
    state.overflow.length === 0 && !alwaysRenderOverflow
      ? null
      : overflowRenderer(state.overflow)

  const repartition = () => {
    if (!wrapper.current) {
      return
    }

    const { offsetWidth, childNodes } = wrapper.current
    const parentWidth = offsetWidth - 50

    let tempWidth = 0
    let itemsCount = 0
    for (itemsCount; itemsCount < childNodes.length; itemsCount += 1) {
      const child = childNodes[itemsCount]
      if (!(child instanceof HTMLElement)) {
        // eslint-disable-next-line no-continue
        continue
      }

      if (
        itemsCount > minVisibleItems &&
        tempWidth + child.offsetWidth > parentWidth
      ) {
        break
      }

      tempWidth += child.offsetWidth
    }

    setState((prevState) => ({
      ...prevState,
      visible: items.slice(0, itemsCount),
      overflow: items.slice(itemsCount),
    }))
  }

  useDeepCompareEffect(() => {
    setState({
      visible: items,
      overflow: [],
      renderCount: Math.random(),
    })
  }, [items])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(repartition, [state.renderCount, width])

  return (
    <div
      ref={(ref) => {
        wrapper.current = ref
        measureRef(ref as HTMLDivElement)
      }}
      className={className}
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        minWidth: 0,
      }}
    >
      {collapseFrom === 'start' ? maybeOverflow : null}
      {state.visible.map(itemRenderer)}
      {collapseFrom === 'end' ? maybeOverflow : null}
    </div>
  )
}
