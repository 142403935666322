import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { Tag, TagProps } from 'src/antd'

import { CompanyEmailAccountMessageStatus } from 'src/gen-types'

export type TCustomerInboxMessageHasTicketChoices = 'YES' | 'NO'

export const customerInboxMessageStatusChoices: Record<
  CompanyEmailAccountMessageStatus,
  ReactNode
> = {
  NEW: <T _str="New" />,
  OPEN: <T _str="Open" />,
  COMPLETED: <T _str="Completed" />,
  REJECTED: <T _str="Rejected" />,
}

export const customerInboxMessageStatusChoicesTagColorMap: Record<
  CompanyEmailAccountMessageStatus,
  TagProps['color']
> = {
  NEW: 'orange',
  OPEN: 'red',
  COMPLETED: 'green',
  REJECTED: 'gray',
}

export const customerInboxStatusChoicesSelectOptions = Object.keys(
  customerInboxMessageStatusChoices
)?.map((e) => ({
  value: e as CompanyEmailAccountMessageStatus,
  label: (
    <Tag
      color={
        customerInboxMessageStatusChoicesTagColorMap[
          e as CompanyEmailAccountMessageStatus
        ]
      }
    >
      {customerInboxMessageStatusChoices[e as CompanyEmailAccountMessageStatus]}
    </Tag>
  ),
}))

export const customerInboxMessageHasTicketChoices: Record<
  TCustomerInboxMessageHasTicketChoices,
  ReactNode
> = {
  YES: <T _str="Yes" />,
  NO: <T _str="No" />,
}

export const customerInboxMessageHasTicketChoicesTagColorMap: Record<
  TCustomerInboxMessageHasTicketChoices,
  TagProps['color']
> = {
  YES: 'green',
  NO: 'red',
}
