import { gql } from '@apollo/client'

import { FrgUserForLink } from 'src/api'

export const GET_CUSTOMER_INBOX_POPUP_INFO = gql`
  ${FrgUserForLink}
  query GetCustomerInboxPopupInfo($id: ID!) {
    companyEmailAccountMessage(id: $id) {
      id
      status
      fromEmail
      companyEmailAccount {
        id
        email
      }
    }
  }
`
