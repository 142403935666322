import { CSSProperties } from 'react'

export type TVariant =
  | 'header-32'
  | 'header-24'
  | 'subHeader-20'
  | 'subHeader-16'
  | 'body-14'
  | 'body-12'
  | 'unset'

export const variants: Record<TVariant, CSSProperties> = {
  'header-32': {
    fontSize: `2rem`,
    lineHeight: 0.875,
  },
  'header-24': {
    fontSize: '1.5rem',
    lineHeight: 1.16,
  },
  'subHeader-20': {
    fontSize: `1.25rem`,
    lineHeight: 1.4,
  },
  'subHeader-16': {
    fontSize: `1rem`,
    lineHeight: 1.5,
  },
  'body-14': {
    fontSize: `0.875rem`,
    lineHeight: 1.4,
  },
  'body-12': {
    fontSize: `0.75rem`,
    lineHeight: 1.3,
  },
  unset: {},
}

export type TFontWeight = `heavy` | `semi-bold` | 'medium' | `regular`

export const fontWeights: Record<TFontWeight, CSSProperties> = {
  heavy: {
    fontWeight: 800,
  },
  'semi-bold': {
    fontWeight: 600,
  },
  medium: {
    fontWeight: 500,
  },
  regular: {
    fontWeight: 400,
  },
}

export type TLoadingSizes = 'small' | 'default' | 'large' | 'full'

export const loadingStylesMap: Record<TLoadingSizes, CSSProperties> = {
  small: { width: 120 },
  default: { width: 150 },
  large: { width: 250 },
  full: { width: '100%' },
}
